import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import API from '../types';
import kratosApi from '@/features/kratos';
import deepMerge from '@/features/helpers/deepMerge';

interface InitialState {
  graph: API.GraphPreferences;
}

const initialState: InitialState = { graph: {} };

export const graphPreferencesSlice = createSlice({
  name: 'graph_preferences',
  initialState,
  reducers: {
    updateGraphPreference(state, action: PayloadAction<API.GraphPreferences>) {
      state.graph = deepMerge(state.graph, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(kratosApi.endpoints.getGraphPreferences.matchFulfilled, (state, action) => {
      state.graph = action.payload;
    });
  },
});

export const selectGraphPreference = (state: RootState) => state.graph_preferences.graph;

export const graphPreferencesReducer = graphPreferencesSlice.reducer;
export const { updateGraphPreference } = graphPreferencesSlice.actions;
