import EllipsisText from '@/components/EllipsisText';
import { PCell } from '../helpers/types';
import { Link } from 'react-router-dom';
import isLink from '../helpers/isLink';
import { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

function isLabel(label: unknown): label is string | string[] {
  return (!!label && typeof label === 'string') || (Array.isArray(label) && label.every(isLabel));
}

export default function DefaultCell<T>({
  cell,
  row,
  column,
  enableOrder,
}: PCell<T> & { enableOrder?: boolean }) {
  const value = cell.getValue();
  const { transition, isDragging, setNodeRef, transform } = useSortable({
    id: column.id,
  });

  const component = useMemo(() => {
    if (isLink(value))
      return (
        <EllipsisText
          label={`${value.label || '-'}`}
          element={<Link to={value.link}>{value.label}</Link>}
        />
      );
    else if (isLabel(value)) {
      return <EllipsisText label={value} />;
    } else if (value === null || value === undefined || value === '') {
      return <EllipsisText label={isLabel(value) ? value : '-'} />;
    } else return <EllipsisText label={`${value}`} />;
  }, [value]);

  return (
    <div
      className="cell"
      key={`cell-${row.id}-${column.id}`}
      ref={setNodeRef}
      style={{
        opacity: enableOrder && isDragging ? 0.5 : undefined,
        transform: enableOrder ? CSS.Translate.toString(transform) : undefined,
        transition: enableOrder ? transition : undefined,
      }}
    >
      {component}
    </div>
  );
}
