import '@/scss/components/Badge.css';
import { ReactNode } from 'react';
import Vector from '@/assets/icons/Vector.svg?react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mantine/core';

export type GeneralBadgeProps = {
  label?: string;
  color: 'indigo' | 'green' | 'yellow' | 'fuchsia' | 'teal' | 'rose' | 'orange' | 'violet';
  icon?: ReactNode;
  onClick?: () => void;
  useAbbreviation?: boolean;
};

// var badge is not created but it can be created use this Badge component
export default function GeneralBadge({ color, label, icon, useAbbreviation }: GeneralBadgeProps) {
  return (
    <>
      {useAbbreviation && label ? (
        <Tooltip withinPortal label={label}>
          <span className={`general-badge general-badge-${color}`}>
            {icon || <span className="label">{label.slice(0, 1)}</span>}
          </span>
        </Tooltip>
      ) : (
        <span className={`general-badge general-badge-${color}`}>
          {icon}
          {label && <span className="label">{label}</span>}
        </span>
      )}
    </>
  );
}

export function OfficialBadge({
  onClick,
  useAbbreviation,
}: {
  onClick?: () => void;
  useAbbreviation?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      onClick={onClick}
      useAbbreviation={useAbbreviation}
      color="indigo"
      label={t('source_badge.official')}
    />
  );
}

export function ReportedBadge({
  onClick,
  useAbbreviation,
}: {
  onClick?: () => void;
  useAbbreviation?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      onClick={onClick}
      useAbbreviation={useAbbreviation}
      color="green"
      label={t('source_badge.reported')}
    />
  );
}

export function ListedBadge({
  onClick,
  useAbbreviation,
}: {
  onClick?: () => void;
  useAbbreviation?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      onClick={onClick}
      useAbbreviation={useAbbreviation}
      color="yellow"
      label={t('source_badge.listed')}
    />
  );
}

export function EstimatedBadge({
  onClick,
  useAbbreviation,
}: {
  onClick?: () => void;
  useAbbreviation?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      onClick={onClick}
      useAbbreviation={useAbbreviation}
      color="fuchsia"
      label={t('source_badge.estimated')}
    />
  );
}

export function ComputedBadge({
  onClick,
  useAbbreviation,
}: {
  onClick?: () => void;
  useAbbreviation?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      onClick={onClick}
      useAbbreviation={useAbbreviation}
      color="teal"
      label={t('source_badge.computed')}
    />
  );
}

export function MarketBadge({
  onClick,
  useAbbreviation,
}: {
  onClick?: () => void;
  useAbbreviation?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      onClick={onClick}
      useAbbreviation={useAbbreviation}
      color="violet"
      label={t('source_badge.market_data')}
    />
  );
}

export function ForecastedBadge({
  onClick,
  useAbbreviation,
}: {
  onClick?: () => void;
  useAbbreviation?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      onClick={onClick}
      useAbbreviation={useAbbreviation}
      color="rose"
      label={t('source_badge.forecasted')}
    />
  );
}

export function BetaBadge({ withLabel }: { withLabel?: boolean }) {
  const { t } = useTranslation();
  return (
    <GeneralBadge
      color="orange"
      label={withLabel ? t('badge.beta') : undefined}
      icon={<Vector />}
      useAbbreviation
    />
  );
}
