import PortfolioModal from '@/components/Portfolios/PortfolioModal';
import { RTKResult } from '@/features/helpers/fiiErrorParser';
import UseNotifications from '@/features/helpers/useNotifications';
import portfolioApi from '@/features/pia/portfolios';
import { FolderAdd } from '@carbon/icons-react';
import { modals } from '@mantine/modals';
import { ReactNode, useCallback, useMemo } from 'react';
import { ActionProps, TableActionFunction } from '../Table/types';
import { OverviewActionFunction } from '../ObjectList/type';
import { TableActionProps } from './type';
import { useTranslation } from 'react-i18next';
import UseMonitorAction from '@/features/helpers/useMonitorAction';

type Asset = TableActionProps['asset'] | 'fund';

type TableActionPropsSpecial = Omit<TableActionProps, 'asset'> & { asset: Asset };

export type OverviewProps = TableActionPropsSpecial & {
  assetId: number;
};

interface Return {
  enabled: boolean;
  label: string;
  icon: ReactNode;
}

type OverviewReturn = Return & {
  function?: OverviewActionFunction;
};

type TableReturn = Return & {
  function?: TableActionFunction;
};

function isOverview(props: unknown): props is OverviewProps {
  return Boolean((props as OverviewProps).assetId);
}

export default function UseAddToPortfolio<P extends OverviewProps | TableActionProps>(
  props: P
): P extends OverviewProps ? OverviewReturn : TableReturn {
  const { asset, enabled, type = 'asset' } = props;
  const [addPortfolioAsset, { isSuccess, error, isLoading }] =
    portfolioApi.useAddPortfolioAssetMutation();
  const [
    createPortfolio,
    { isSuccess: portfolioCreated, isLoading: isCreating, error: creationError },
  ] = portfolioApi.useCreatePortfolioMutation();
  const [getPortfolioContains, { isLoading: isVerifying, error: verifyError }] =
    portfolioApi.useGetContainsDwellingsMutation();
  const notification = UseNotifications();
  const { t } = useTranslation();

  const createPortfolioWithAsset = useCallback(
    (value: string, item: number[], groups: number[]) => {
      const param = { title: value, [`${asset}s`]: item, buildingGroups: groups, type };
      createPortfolio(param);
    },
    [asset, createPortfolio, type]
  );

  const addToPortfolio = useCallback(
    (id: string, item: number[], groups: number[]) => {
      const param = { id, [`${asset}s`]: item, buildingGroups: groups };
      if (asset === 'building') {
        getPortfolioContains({ portfolioId: id, buildingId: item, buildingGroup: groups }).then(
          (res) => {
            const { data: contains, error } = res as RTKResult<Boolean>;
            if (error) return;
            if (contains) {
              return modals.openConfirmModal({
                closeOnConfirm: true,
                title: t('actions.add_to_portfolios.confrm_modal.title'),
                labels: {
                  confirm: t('actions.add_to_portfolios.confrm_modal.buttons.confirm'),
                  cancel: t('actions.add_to_portfolios.confrm_modal.buttons.cancel'),
                },
                children: (
                  <div className="confirm-modal">
                    {t('actions.add_to_portfolios.confirm_modal.text_1', {
                      building_label: item.length > 1 ? 'these buildings' : 'this building',
                    })}
                    <br />
                    {t('actions.add_to_portfolios.confirm_modal.text_2')}
                  </div>
                ),
                onCancel: () => {
                  modals.open({
                    title: t('actions.add_to_portfolios.title'),
                    children: (
                      <PortfolioModal
                        hasPortfolioList={true}
                        onItemClick={(id) => addToPortfolio(id, item, groups)}
                        onCreateClick={(value) => createPortfolioWithAsset(value, item, groups)}
                      />
                    ),
                  });
                },
                onConfirm: () => addPortfolioAsset(param),
              });
            } else addPortfolioAsset(param);
          }
        );
      } else addPortfolioAsset(param);
    },
    [asset, addPortfolioAsset, getPortfolioContains, t, createPortfolioWithAsset]
  );

  UseMonitorAction(
    undefined,
    error,
    isSuccess,
    isLoading,
    t('actions.add_to_portfolios.success_add_portfolio.message', {
      asset,
      portfolio_state: '',
    }),
    notification
  );
  UseMonitorAction(
    undefined,
    creationError,
    portfolioCreated,
    isCreating,
    t('actions.add_to_portfolios.success_add_portfolio.message', {
      asset,
      portfolio_state: t('actions.add_to_portfolios.modal.new').toLowerCase(),
    }),
    notification
  );
  UseMonitorAction(undefined, verifyError, undefined, isVerifying, undefined, notification);

  const action = useMemo<OverviewReturn | TableReturn>(() => {
    const fn = ({ rows, subrows }: ActionProps) => {
      const items = (subrows ? subrows : rows).map((row) => Number(row));
      const groups = (subrows ? rows : []).map((row) => Number(row));
      modals.open({
        title: t('actions.add_to_portfolios.title'),
        children: (
          <PortfolioModal
            hasPortfolioList={true}
            onItemClick={(id) => addToPortfolio(id, items, groups)}
            onCreateClick={(value) => createPortfolioWithAsset(value, items, groups)}
          />
        ),
      });
    };

    const action = {
      enabled: enabled === undefined ? true : enabled,
      label: t('actions.add_to_portfolios.title'),
      icon: <FolderAdd />,
    };
    if (isOverview(props))
      return {
        ...action,
        function: (_asset: unknown) => {
          fn({ rows: [String(props.assetId)], subrows: undefined });
        },
      };
    else
      return {
        ...action,
        function: fn,
      };
  }, [enabled, t, props, addToPortfolio, createPortfolioWithAsset]);

  return action;
}
