import { ScrollArea } from '@mantine/core';
import '@/scss/components/Policy.css';
import { useViewportSize } from '@mantine/hooks';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Policy() {
  const { height } = useViewportSize();
  const { t } = useTranslation();

  return (
    <ScrollArea.Autosize
      className="policy-container-scrollarea"
      // 210? Don't ask questions...
      mah={height - 210}
      scrollbarSize={8}
      scrollHideDelay={1000}
      type="auto"
    >
      <div className="policy-container">
        <h1>{t('policy.title')}</h1>
        <div className="policy-container-part">
          <div className="policy-container-paragraph">
            <p>{t('policy.introduction')}</p>
            <p>{t('policy.company_info')}</p>
            <p>{t('policy.eea_representative')}</p>
            <p>{t('policy.policy_date')}</p>
            <p>
              <Trans i18nKey="policy.agreement">
                By accessing the website <Link to="https://quanthome.com">www.quanthome.com</Link>{' '}
                ("Site"), which we operate, you expressly agree to our processing your data in
                accordance with this Policy.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="policy.inquiries">
                For any inquiries regarding this Policy or your data's use, please contact us at{' '}
                <Link to="mailto:contact@quanthome.com">contact@quanthome.com</Link> or call +41 21
                312 16 93.
              </Trans>
            </p>
            <p>{t('policy.terms_invitation')}</p>
          </div>
          <h2>{t('policy.what_is_personal_data')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.personal_data_definition')}</p>
          </div>
          <h2>{t('policy.type_of_data')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.data_provided')}</p>
            <ul>
              <li> - {t('policy.data_list.first_name')}</li>
              <li> - {t('policy.data_list.last_name')}</li>
              <li> - {t('policy.data_list.company_name')}</li>
              <li> - {t('policy.data_list.email_address')}</li>
              <li> - {t('policy.data_list.phone_number')}</li>
            </ul>
            <p>{t('policy.additional_information')}</p>
          </div>
          <h2>{t('policy.why_collect_data')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.data_processing_basis')}</p>
            <ul>
              <li> - {t('policy.data_usage.account_creation')}</li>
              <li> - {t('policy.data_usage.legal_age_verification')}</li>
              <li> - {t('policy.data_usage.contact_information')}</li>
              <li> - {t('policy.data_usage.payment_verification')}</li>
              <li> - {t('policy.data_usage.cookies_analytics')}</li>
              <li> - {t('policy.data_usage.legal_compliance')}</li>
            </ul>
            <p>{t('policy.consent')}</p>
            <p>{t('policy.non_identifying_data_use')}</p>
          </div>
          <h2>{t('policy.data_sharing')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.sharing_overview')}</p>
            <ul>
              <li> - {t('policy.sharing_list.legal_requirements')}</li>
              <li> - {t('policy.sharing_list.service_execution')}</li>
              <li> - {t('policy.sharing_list.corporate_events')}</li>
            </ul>
            <p>{t('policy.international_transfers')}</p>
            <p>{t('policy.transfer_consent')}</p>
          </div>
          <h2>{t('policy.data_hosting')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.hosting_details')}</p>
            {/* TODO: */}
          </div>
          <h2>{t('policy.data_handling')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.processing_methods')}</p>
            <p>{t('policy.law_compliance')}</p>
            <p>{t('policy.anonymization_use')}</p>
          </div>
          <h2>{t('policy.data_protection')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.security_measures')}</p>
            <p>{t('policy.third_party_security')}</p>
            <p>{t('policy.credential_safety')}</p>
            <p>{t('policy.security_breach_protocol')}</p>
          </div>
          <h2>{t('policy.data_retention')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.retention_period')}</p>
            <p>{t('policy.retention_request')}</p>
            <p>{t('policy.anonymization_alternative')}</p>
          </div>
          <h2>{t('policy.your_rights')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.rights_overview')}</p>
            <p>{t('policy.access_right')}</p>
            <p>{t('policy.data_correction')}</p>
            <p>{t('policy.legal_processing_objection')}</p>
            <p>{t('policy.data_deletion_request')}</p>
            <p>{t('policy.consent_withdrawal')}</p>
            <p>{t('policy.eea_rights')}</p>
            <ul>
              <li> - {t('policy.eea_rights_list.access')}</li>
              <li> - {t('policy.eea_rights_list.forgotten')}</li>
              <li> - {t('policy.eea_rights_list.rectification')}</li>
              <li> - {t('policy.eea_rights_list.complaint')}</li>
              <li> - {t('policy.eea_rights_list.processing_restriction')}</li>
              <li> - {t('policy.eea_rights_list.objection')}</li>
              <li> - {t('policy.eea_rights_list.automated_decisions')}</li>
            </ul>
            <p>
              <Trans i18nKey="policy.rights_exercise">
                For further details or to exercise your rights, contact us at{' '}
                <Link to="mailto:contact@quanthome.com">contact@quanthome.com</Link>.
              </Trans>
            </p>
            <p>{t('policy.data_provision_consequences')}</p>
          </div>
        </div>
        <h1>{t('policy.cookies_section')}</h1>
        <div className="policy-container-part">
          <h2>{t('policy.cookies.what_are_cookies')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.cookies.definition')}</p>
            <p>{t('policy.cookies.consent')}</p>
            <p>
              <Trans i18nKey="policy.cookies.more_information">
                For more information, visit{' '}
                <Link to="https://gdpr.eu/cookies/">https://gdpr.eu/cookies/</Link>. Also, check
                your browser or electronic device's help section for specific instructions on
                managing cookies.
              </Trans>
            </p>
          </div>
          <h2>{t('policy.cookies.usage')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.cookies.purpose')}</p>
          </div>
          <h2>{t('policy.cookies.types')}</h2>
          <div className="policy-container-paragraph">
            <p>{t('policy.cookies.types_overview')}</p>
            <ul>
              <li> - {t('policy.cookies.types_list.essential')}</li>
              <li> - {t('policy.cookies.types_list.functional')}</li>
              <li> - {t('policy.cookies.types_list.statistical')}</li>
              <li>
                -{' '}
                <Trans i18nKey="policy.cookies.types_list.third_party">
                  Third-party Cookies: Set by third parties we use for various services (e.g., Site
                  analysis or advertising). QUANTHOME uses Google Analytics cookies, which provide
                  information on user activities without identifying them. Google Analytics cookies
                  don't collect Data about Site visitors. For more about Google Analytics, click{' '}
                  <Link to="https://support.google.com/analytics/answer/6004245?hl=fr">
                    https://support.google.com/analytics/answer/6004245?hl=fr
                  </Link>
                  .
                </Trans>
              </li>
            </ul>
          </div>
        </div>
        <h1>{t('policy.legal_updates')}</h1>
        <div className="policy-container-part">
          <p>{t('policy.updates')}</p>
        </div>
      </div>
    </ScrollArea.Autosize>
  );
}
