import { useMemo } from 'react';
import '@/scss/components/ObjectList.css';
import { ObjectListItemType, ObjectListType } from './type';
import AssetSpecialStatusBadge from './Badge/AssetSpecialStatus';
import MarketValueBadge from './Badge/MarketValue';
import LegendsBadge from './Badge/Legend';
import ReportDateBadge from './Badge/ReportDate';
import ActionsBadge from './Badge/Actions';
import DescriptionBadge from './Badge/Description';
import TitleBadge from './Badge/Title';
import OwnerBadge from './Badge/Owner';

export default function ObjectListItem(
  props: Omit<ObjectListType, 'objectItems'> & { item: ObjectListItemType }
) {
  const {
    type,
    icon,
    id,
    title,
    subtitle,
    reportDate,
    isAnnual,
    legends,
    description,
    isTitleEditable,
    status,
    marketValue,
    funds_legal_form,
  } = props.item;
  const actions = props.actions;
  //   const [collapsible, setCollapsible] = useState<CollapsibleObj>([]);
  //   const value = useId();

  const isOneRow = useMemo(() => {
    return !props.item || description === undefined || description.length === 0;
  }, [description, props.item]);

  return (
    <>
      {/* <Accordion.Item value={`${value}`} className="object-list-item"> */}
      <div className="object-list-item">
        <div className="object-list-item-left object-list-item-icon">{icon}</div>
        <div className="object-list-item-right">
          <div className={`object-list-item-upper${isOneRow ? '-empty' : ''}`}>
            <div className="asset-information">
              <TitleBadge title={title} type={type} id={id} isTitleEditable={isTitleEditable} />
              {subtitle && <div className="title-sub">{subtitle}</div>}
              <MarketValueBadge {...marketValue} />
              <LegendsBadge legends={legends} />
              <AssetSpecialStatusBadge translation={status?.translation} id={status?.id} />
              <OwnerBadge ownerLegalForms={funds_legal_form} />
            </div>
            <div className="object-list-item-upper-section-empty"></div>
            {actions || reportDate ? (
              <div className="object-list-item-upper-right">
                <ReportDateBadge reportDate={reportDate} isAnnual={isAnnual} />
                <ActionsBadge actions={actions} type={type} id={id} />
                {/* {collapsible &&
                props.openableChildren &&
                legends?.some(
                  (legend) =>
                    (legend.type === 'buildings_nb' || legend.type === 'dwellings_nb') &&
                    legend.value > 0
                ) && (
                  <Accordion.Control
                    onClick={async () => {
                      if (props.onOpen) {
                        const collapsible = await props.onOpen();
                        setCollapsible(collapsible);
                      }
                    }}
                    className="collapsible-control"
                  />
                )} */}
              </div>
            ) : null}
          </div>
          {/* <Accordion.Panel className="object-list-item-collapsible">
            {collapsible.length > 0 && props.openableChildren ? (
              <div key={collapsible.length} className="assets-dropdown">
                {collapsible.map((col, index) => (
                  <React.Fragment key={index}>
                    {col.contents.length > 0 && <DropdownCol collapsibleCol={col} />}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <Skeleton height={178} />
            )}
          </Accordion.Panel> */}
          {!isOneRow && (
            <div className="object-list-item-lower">
              <div className="lower-flex">
                <DescriptionBadge description={description} />
              </div>
              <div className="object-list-item-lower-section-empty"></div>
            </div>
          )}
        </div>
      </div>
      {/* </Accordion.Item> */}
    </>
  );
}
