import { formatFullFloat } from '@/features/helpers/numbers';
import '@/scss/components/VariationBadge.css';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: number;
  suffix: string;
  invertColors?: boolean;
}

export default function VariationBadge(props: IProps) {
  const { t } = useTranslation();
  const { value, suffix, invertColors } = props;

  const color = useMemo(() => {
    if (invertColors === undefined) return 'grey';
    const formattedValue = parseFloat(value.toFixed(2));
    if (invertColors) {
      if (formattedValue > 0) return 'red';
      if (formattedValue < 0) return 'green';
      if (formattedValue >= 0 && formattedValue < 0.01) return 'grey';
    }
    if (formattedValue > 0) return 'green';
    if (formattedValue < 0) return 'red';
    if (formattedValue >= 0 && formattedValue < 0.01) return 'grey';
  }, [invertColors, value]);

  const label = useMemo(() => {
    if (value > 0) return `+${formatFullFloat(value)} ${t(suffix)}`;
    return `${formatFullFloat(value)} ${t(suffix)}`;
  }, [value, suffix, t]);

  return <span className={`variation-badge ${color}`}>{label}</span>;
}
