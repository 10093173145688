import TrendItem from '@/components/Widget/TrendItem';
import { ItemTrendType } from '@/components/Widget/types';

type IProps = {
  description?: Array<ItemTrendType>;
};

export default function DescriptionBadge({ description }: IProps) {
  if (!description?.length) return null;

  return (
    <>
      {description.map((item, index) => (
        <span key={`trend-${index}`} className="description-col">
          <TrendItem item={item} />
        </span>
      ))}
    </>
  );
}
