import { useEffect } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import UseNotifications from './useNotifications';
import { UseParseError, parseRTKError } from './fiiErrorParser';
import API from '../api/types';
import { notifications } from '@mantine/notifications';

export default function UseMonitorAction(
  warnings: Array<API.Warning> | undefined,
  error: FetchBaseQueryError | SerializedError | undefined,
  isSuccess: boolean | undefined,
  isLoading: boolean | undefined,
  successMsg: string | undefined,
  passedNotification?: ReturnType<typeof UseNotifications>
) {
  const { t } = useTranslation();
  const notification = passedNotification ? passedNotification : UseNotifications();
  const { message } = UseParseError(error);

  useEffect(() => {
    if (isLoading) {
      notification.show({
        title: t('actions.update.processing.title'),
        loading: true,
        message: t('actions.update.processing.message'),
        color: 'blue',
        autoClose: 1000 * 30,
      });
    }
  }, [isLoading, notification, t]);

  useEffect(() => {
    if (isSuccess) {
      notification.show({
        title: t('common.success'),
        message: successMsg ?? t('actions.update.processing.done'),
        color: 'green',
        loading: false,
      });
    }
  }, [isSuccess, successMsg, notification, t]);

  useEffect(() => {
    if (message) {
      notification.show({
        title: t('common.error'),
        message,
        color: 'yellow',
        autoClose: 1000 * 10,
        loading: false,
      });
    }
  }, [message, notification, t]);

  useEffect(() => {
    if (warnings) {
      warnings.map((warning) => {
        const parsedWarning = parseRTKError(warning);
        if (parsedWarning?.userMessage)
          notifications.show({
            title: t('common.warning'),
            message: parsedWarning?.userMessage,
            color: 'yellow',
            autoClose: 1000 * 10,
            loading: false,
          });
      });
    }
  }, [warnings, t]);
}
