import { createApi } from '@reduxjs/toolkit/query/react';
import fetchCustomQuery from '../helpers/reAuth';
import Pia from './type';
import { ResultTypeItem } from '@/components/SearchBar/type';
import { formatBuildingsRow, formatSameBuildingDwellingsRow } from '../api/formatRowHelper';
import { formatCantonRent, formatIndicators, formatTrend } from './helper';
import { ItemNumType } from '@/components/Widget/types';
import { BarLineDataType } from '@/components/Charts/types';
import { Criterias } from '../api/screener/screenerTypes';

const { VITE_API_URI } = import.meta.env;

const piaApi = createApi({
  reducerPath: 'piaApi',
  baseQuery: fetchCustomQuery({
    baseUrl: VITE_API_URI,
    credentials: 'include',
  }),
  tagTypes: ['Asset'],
  endpoints: (builder) => ({
    getStatsFequencies: builder.query<Array<number>, number>({
      query: (cantonId) => `stats/frequencies/rooms/${cantonId}`,
      transformResponse: (data: { frequencies: Array<number> }) => {
        return data.frequencies.sort((a, b) => b - a);
      },
    }),
    getStats: builder.query<
      Array<{ label: string; value: number }>,
      { cantonId: number; timestamp: number }
    >({
      query: ({ cantonId, timestamp }) => `stats/rooms/${cantonId}/${timestamp}`,
      transformResponse: (data: { stats: Record<string, number> }) => {
        return Object.keys(data.stats).map((key) => ({ label: key, value: data.stats[key] }));
      },
    }),
    getStatsQuantilesM2: builder.query<
      Array<{ date: number; low: number; high: number; close: number; open: number }>,
      number
    >({
      query: (cantonId) => `stats/rent-m2/${cantonId}`,
      transformResponse: (data: {
        stats: Array<{ date: number; low: number; high: number; close: number; open: number }>;
      }) => {
        return data.stats;
      },
    }),
    getStatsQuantilesRooms: builder.query<
      Array<{ date: number; low: number; high: number; close: number; open: number }>,
      number
    >({
      query: (cantonId) => `stats/rent-room/${cantonId}`,
      transformResponse: (data: {
        stats: Array<{ date: number; low: number; high: number; close: number; open: number }>;
      }) => {
        return data.stats;
      },
    }),
    getCodeOptions: builder.query<
      Array<unknown>,
      'GSTAT' | 'GKAT' | 'GKLAS' | 'GENH1' | 'GENW1' | 'GWAERZH1' | 'GWAERZW1'
    >({
      query: (code) => `screener/options/${code}`,
      transformResponse: (data: {
        options: Array<{ id: number; text: string; long_text: string }>;
      }) => {
        return data.options.map(({ id, text }) => ({ value: id, label: text }));
      },
    }),
    getMunicipalities: builder.query<Array<unknown>, Pia.CantonId>({
      query: (cantonId) => `screener/municipalities/${cantonId}`,
      transformResponse: (data: {
        municipalities: Array<{ id: number; text: string; canton_id: Pia.CantonId }>;
      }) => {
        return data.municipalities.map(({ id, text }) => ({ value: id, label: text }));
      },
    }),
    getDistricts: builder.query<Array<unknown>, number>({
      query: (municipalityId) => `screener/districts/${municipalityId}`,
      transformResponse: (data: {
        districts: Array<{ id: number; text: string; municipality_id: number }>;
      }) => {
        return data.districts.map(({ id, text }) => ({ value: id, label: text }));
      },
    }),
    getScreenerResults: builder.query<
      Array<any>,
      Criterias & { page?: number } & { sort?: { column: string; order: 'ASC' | 'DESC' } }
    >({
      query: (criterias) => ({
        url: 'screener',
        method: 'POST',
        body: criterias,
      }),
      transformResponse: (data: { type: string; results: Array<Record<string, any>> }) => {
        const { type } = data;
        const keys = data.results.map((data) => Object.keys(data || {})).flat();
        const noDoublonKeys = Array.from(new Set(['id', ...keys]));
        if (!noDoublonKeys.length) return [];
        return data.results.map((data) => {
          const newData = noDoublonKeys.reduce((newData, key, _) => {
            let value = data[key];
            value = Array.isArray(value) ? value.map((value) => `${value}`).join(' ') : value;
            if (key.toLowerCase() === 'id')
              return { ...newData, /* [key]: value, */ $link: `/${type}/${value}` };
            return { ...newData, [key]: value };
          }, {});
          return newData;
        }, []);
      },
    }),
    getDwellingOfBuilding: builder.query({
      query: (id: number | string) => `details/buildings/${id}/dwellings/rows`,
      transformResponse: formatSameBuildingDwellingsRow,
    }),
    getBuildingOfLot: builder.query({
      query: (id: number | string) => `details/lots/${id}/buildings/rows`,
      transformResponse: formatBuildingsRow,
    }),
    getSearchSuggestions: builder.query<ResultTypeItem, { type: Pia.SearchTypes; text: string }>({
      query: ({ type, text }: { type: Pia.SearchTypes; text: string }) =>
        `search?type=${type}&text=${text}`,
    }),

    getDashboardIndicators: builder.query<
      {
        indicators: Array<ItemNumType>;
        indices: Array<ItemNumType>;
      },
      void
    >({
      query: () => ({
        url: '/dashboard/indicators',
      }),
      transformResponse: (data: { indicators: Array<Pia.Indicator> }) => {
        return formatIndicators(data.indicators);
      },
    }),
    getDashboardCantonRent: builder.query<BarLineDataType[], void>({
      query: () => ({
        url: '/dashboard/canton-rent',
      }),
      transformResponse: (data: { rent: Array<Pia.CantonRent> }) => {
        return formatCantonRent(data.rent);
      },
    }),
    getDashboardTrend: builder.query<BarLineDataType[], void>({
      query: () => ({
        url: '/dashboard/trend',
      }),
      transformResponse: (data: { trend: Array<Pia.TrendPoint> }) => {
        return formatTrend(data.trend);
      },
    }),
    getCountries: builder.query<Array<string>, void>({
      query: () => ({
        url: '/general/countries',
      }),
      transformResponse: (data: { countries: Array<string> }) => data.countries,
    }),
  }),
});

export default piaApi;
