import { Construction, DocumentTasks, Earthquake, TaskView } from '@carbon/icons-react';
import '@/scss/components/AssetSpecialStatus.css';
import { ReactNode } from 'react';
import { AssetStatusProps } from '../type';
import startCase from 'lodash.startcase';

const Icon: { [id: number]: ReactNode } = {
  // If is is 1001 the status is in project
  [1001]: <TaskView />,
  // If is is 1002 the status is authorized
  [1002]: <DocumentTasks />,
  // If is is 1003 the status is under construction
  [1003]: <Construction />,
  // If is is 1007 the status is demolished
  [1007]: <Earthquake />,
};

export default function AssetSpecialStatusBadge(props: AssetStatusProps) {
  const { translation, id } = props;

  // if status is 1004 the building is existing so we don't need to show any special status
  if (!translation || !id || id === 1004) return null;

  return (
    <div className="asset-special-status object-list-item-upper-section">
      {id && Icon[id]}
      {startCase(translation)}
    </div>
  );
}
