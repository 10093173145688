import Pia from './type';
import apiWrapper from '../api';
import {
  formatDemographicAges,
  formatDemographicForeigners,
  formatDemographicGender,
} from '@/pages/sections/helper';
import Financials from '@/components/FinancialsWidget/type';
import { formatComparables } from '../api/formatRowHelper';
import { TrackerTypes } from '@/pages/WatchListPage';
import { TagDescription } from '@reduxjs/toolkit/query';

const assetApi = apiWrapper
  .enhanceEndpoints({
    addTagTypes: [
      'Status',
      'Ages',
      'Gender',
      'Foreigners',
      'Address',
      'Energy',
      'Risk',
      'Environment',
      'Governance',
      'Overview',
      'Admin',
      'General',
      'Amenities',
      'Promiscuity',
      'Accessibility',
      'Valuation',
      'Marker',
      'Context',
      'Taxes',
      'Trackers',
      'Density',
      'TransportationAccessibility',
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAssetOverview: build.query<
        Pia.Overview,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number | string }
      >({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/overview` }),
        providesTags: (_result, _error, query) => [
          { type: 'Overview', id: `${query.id}-${query.type}` },
        ],
        keepUnusedDataFor: 60,
      }),
      addTrackers: build.mutation({
        query: (data: {
          buildings?: Array<string | number>;
          dwellings?: Array<string | number>;
          buildingGroups?: Array<string | number>;
          type: 'asset' | 'transaction';
        }) => ({
          url: 'trackers',
          method: 'PUT',
          body: { ...data },
        }),
        invalidatesTags: (_r, _e, query) => {
          const mapper = (type: 'buildings' | 'dwellings') => (id: string | number) => {
            const tag: TagDescription<'Overview'> = {
              type: 'Overview',
              id: `${id}-${type}`,
            };
            return tag;
          };
          const buildingsTrackersTag: TagDescription<'Trackers'> = {
            type: 'Trackers',
            id: 'buildings',
          };
          const dwellingsTrackersTag: TagDescription<'Trackers'> = {
            type: 'Trackers',
            id: 'dwellings',
          };

          const trackersTag: TagDescription<'Trackers'>[] = [
            ...(query.buildings?.length ? [buildingsTrackersTag] : []),
            ...(query.dwellings?.length ? [dwellingsTrackersTag] : []),
          ];

          const tags = [
            ...(query.buildings?.map(mapper('buildings')) || []),
            ...(query.dwellings?.map(mapper('dwellings')) || []),
            ...trackersTag,
          ];

          return tags;
        },
      }),
      deleteTrackers: build.mutation({
        query: (data: {
          buildings?: Array<string | number>;
          dwellings?: Array<string | number>;
        }) => ({
          url: 'trackers',
          method: 'DELETE',
          body: { dwellings: data.dwellings, buildings: data.buildings },
        }),
        invalidatesTags: (_r, _e, query) => {
          const mapper = (type: 'buildings' | 'dwellings') => (id: string | number) => {
            const tag: TagDescription<'Overview'> = { type: 'Overview', id: `${id}-${type}` };
            return tag;
          };
          const buildingsTrackersTag: TagDescription<'Trackers'> = {
            type: 'Trackers',
            id: 'buildings',
          };
          const dwellingsTrackersTag: TagDescription<'Trackers'> = {
            type: 'Trackers',
            id: 'dwellings',
          };

          const trackersTag: TagDescription<'Trackers'>[] = [
            ...(query.buildings?.length ? [buildingsTrackersTag] : []),
            ...(query.dwellings?.length ? [dwellingsTrackersTag] : []),
          ];

          const tags = [
            ...(query.buildings?.map(mapper('buildings')) || []),
            ...(query.dwellings?.map(mapper('dwellings')) || []),
            ...trackersTag,
          ];
          return tags;
        },
      }),
      getTrackers: build.query<Array<number>, { type: TrackerTypes; page: number }>({
        query: ({ page, type }: { page?: number; type?: TrackerTypes }) => ({
          params: { page, type },
          url: 'trackers',
        }),
        keepUnusedDataFor: 60,
        providesTags: (_result, _error, query) => {
          return [{ type: 'Trackers', id: query.type }];
        },
      }),
      getAssetAddresses: build.query<
        Array<Pia.Address>,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/addresses` }),
        transformResponse: (data: { addresses: Array<Pia.Address> }) => data.addresses,
        providesTags: (_result, _error, query) => {
          return [{ type: 'Address', id: query.id, assetType: query.type }];
        },
      }),
      getEnergy: build.query<Pia.Energy, { type: 'dwellings' | 'buildings' | 'lots'; id: number }>({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/energy` }),
        providesTags: (_result, _error, query) => {
          return [{ type: 'Energy', id: query.id, assetType: query.type }];
        },
      }),
      getEstimatedIdcCo2: build.query<
        Pia.Energy['idc_co2'],
        { type: 'dwellings' | 'buildings' | 'lots'; id: number; deltaT?: string }
      >({
        query: ({ type, id, deltaT }) => ({
          url: deltaT
            ? `details/${type}/${id}/energy/idc-co2/estimated?deltaT=${deltaT}`
            : `details/${type}/${id}/energy/idc-co2/estimated`,
        }),
        transformResponse: (data: { idc_co2: Pia.Energy['idc_co2'] }) => data.idc_co2,
        providesTags: (_result, _error, query) => {
          return [{ type: 'Energy', id: query.id, assetType: query.type }];
        },
      }),
      getEstimatedIdcCo2TimeSeries: build.query<
        Pia.Energy['idc_co2'][],
        { type: 'dwellings' | 'buildings' | 'lots'; id: number; deltaT?: string }
      >({
        query: ({ type, id, deltaT }) => ({
          url: deltaT
            ? `details/${type}/${id}/energy/idc-co2/estimated/time-series?deltaT=${deltaT}`
            : `details/${type}/${id}/energy/idc-co2/estimated/time-series`,
        }),
        transformResponse: (data: { idc_co2: Pia.Energy['idc_co2'][] }) => data.idc_co2,
        providesTags: (_result, _error, query) => {
          return [{ type: 'Energy', id: query.id, assetType: query.type }];
        },
      }),
      getEnvironment: build.query<
        Pia.Environment,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/environmental` }),
        providesTags: (_result, _error, query) => {
          return [{ type: 'Environment', id: query.id, assetType: query.type }];
        },
      }),
      getAssetAdmin: build.query<
        Pia.Admin,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/description/administrative` }),
        transformResponse: (data: { administrative: Pia.Admin }) => data.administrative,
        providesTags: (_result, _error, query) => {
          return [{ type: 'Admin', id: query.id, assetType: query.type }];
        },
      }),
      getAssetMarker: build.query<
        Pia.Marker,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/description/marker` }),
        transformResponse: (data: { marker: Pia.Marker }) => data.marker,
        providesTags: (_result, _error, query) => {
          return [{ type: 'Marker', id: query.id, assetType: query.type }];
        },
      }),
      getAssetGeneralDescrip: build.query<
        Pia.GeneralDescription,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/description/general` }),
        transformResponse: (data: { general: Pia.GeneralDescription }) => data.general,
        providesTags: (_result, _error, query) => {
          return [{ type: 'General', id: query.id, assetType: query.type }];
        },
      }),
      getAssetAmenities: build.query<
        Pia.Amenities,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({ url: `details/${type}/${id}/description/amenities` }),
        transformResponse: (data: { amenities: Pia.Amenities }) => data.amenities,
        providesTags: (_result, _error, query) => {
          return [{ type: 'Amenities', id: query.id, assetType: query.type }];
        },
      }),
      getDemographicStatus: build.query<
        Pia.Status,
        { cantonCode?: string; municipalityNb?: number }
      >({
        query: ({ cantonCode, municipalityNb }) => ({
          url: 'stats/population/status',
          params: getDemographicParams({ cantonCode, municipalityNb }),
        }),
        transformResponse: (status: Pia.Status) => {
          return status;
        },
        providesTags: (_result, _error, query) => {
          return [{ type: 'Status', by: query.cantonCode || query.municipalityNb || 'country' }];
        },
      }),
      getDemographicAges: build.query<
        ReturnType<typeof formatDemographicAges>,
        { cantonCode?: string; municipalityNb?: number }
      >({
        query: ({ cantonCode, municipalityNb }) => ({
          url: 'stats/population/ages-by-gender',
          params: getDemographicParams({ cantonCode, municipalityNb }),
        }),
        transformResponse: (ages: Pia.Ages) => {
          return formatDemographicAges(ages);
        },
        providesTags: (_result, _error, query) => {
          return [{ type: 'Ages', by: query.cantonCode || query.municipalityNb || 'country' }];
        },
      }),
      getDemographicGender: build.query<
        ReturnType<typeof formatDemographicGender>,
        { cantonCode?: string; municipalityNb?: number }
      >({
        query: ({ cantonCode, municipalityNb }) => ({
          url: 'stats/population/gender',
          params: getDemographicParams({ cantonCode, municipalityNb }),
        }),
        transformResponse: (gender: Pia.Gender) => {
          return formatDemographicGender(gender);
        },
        providesTags: (_result, _error, query) => {
          return [{ type: 'Gender', by: query.cantonCode || query.municipalityNb || 'country' }];
        },
      }),
      getDemographicForeigners: build.query<
        ReturnType<typeof formatDemographicForeigners>,
        { cantonCode?: string; municipalityNb?: number }
      >({
        query: ({ cantonCode, municipalityNb }) => ({
          url: 'stats/population/citizenship',
          params: getDemographicParams({ cantonCode, municipalityNb }),
        }),
        transformResponse: (citizenship: Pia.Foreigners) => {
          return formatDemographicForeigners(citizenship);
        },
        providesTags: (_result, _error, query) => {
          return [
            { type: 'Foreigners', by: query.cantonCode || query.municipalityNb || 'country' },
          ];
        },
      }),
      getPromiscuity: build.query<
        Pia.PromiscuityNumber,
        { cantonCode?: string; municipalityNb?: number } // keep only municipality
      >({
        query: ({ cantonCode, municipalityNb }) => ({
          url: 'stats/promiscuity',
          params: getDemographicParams({ cantonCode, municipalityNb }),
        }),
        providesTags: (_result, _error, query) => {
          return [
            { type: 'Promiscuity', by: query.cantonCode || query.municipalityNb || 'country' },
          ];
        },
      }),
      getTaxes: build.query<Pia.Taxes, { cantonId?: number; municipalityNb?: number }>({
        query: ({ cantonId, municipalityNb }) => ({
          url: 'stats/taxes',
          params: { cantonId, municipalityId: municipalityNb },
        }),
        providesTags: (_result, _error, query) => {
          return [{ type: 'Taxes', by: `${query.cantonId}-${query.municipalityNb}` }];
        },
      }),
      getAccessibility: build.query<
        Pia.Accessibility,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({
          url: `details/${type}/${id}/accessibility`,
        }),
        providesTags: (_result, _error, query) => {
          return [{ type: 'Accessibility', id: query.id, assetType: query.type }];
        },
      }),
      getFinancialsRent: build.query<
        Financials.AssetRent,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({
          url: `details/${type}/${id}/financials/rent`,
        }),
      }),
      getFinancialsMetrics: build.query<
        Financials.BuildingsMetrics | Financials.DwellingsMetrics,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({
          url: `details/${type}/${id}/financials/metrics`,
        }),
      }),
      getDensity: build.query<
        { density: Pia.AssetDensity },
        { type?: 'dwellings' | 'buildings' | 'lots'; id: number | string }
      >({
        query: ({ type, id }) => ({
          url: `details/${type}/${id}/density`,
        }),
        providesTags: (_result, _error, query) => {
          return [{ type: 'Density', id: query.id, assetType: query.type }];
        },
      }),
      getContext: build.query<
        Record<string, Pia.Context>,
        {
          municipalityId?: number;
          cantonId?: number;
          type: string;
          category: Pia.ContextCategory;
        }
      >({
        query: ({ municipalityId, cantonId, type, category }) => ({
          url: `context/${type}/${category}`,
          params: getContextParams(cantonId, municipalityId),
        }),
        providesTags: (_result, _error, query) => {
          return [
            {
              type: 'Context',
              by: `${query.type}.${query.category}.${
                query.cantonId || query.municipalityId || 'country'
              }`,
            },
          ];
        },
        transformResponse: (data: { context: Record<string, Pia.Context> }) => data.context,
      }),
      getComparables: build.query({
        query: ({ type, id }) => ({
          url: `details/${type}/${id}/financials/comparables`,
        }),
        transformResponse: formatComparables,
      }),
      getCantonValuations: build.query<Pia.Valuation, { cantonId: number | string }>({
        query: ({ cantonId }) => ({
          url: `stats/${cantonId}/valuation`,
        }),
        transformResponse: (data: { valuations: Pia.Valuation }) => {
          return data.valuations;
        },
        providesTags: (_result, _error, query) => {
          return [{ type: 'Valuation', by: query.cantonId }];
        },
      }),
      getAssetValuations: build.query<
        Pia.AssetValuation,
        { type: 'dwellings' | 'buildings' | 'lots'; id: number }
      >({
        query: ({ type, id }) => ({
          url: `details/${type}/${id}/valuation`,
        }),
        transformResponse: (data: { valuations: Pia.AssetValuation }) => {
          return data.valuations;
        },
        providesTags: (_result, _error, query) => {
          return [{ type: 'Valuation', id: query.id, assetType: query.type }];
        },
      }),
      getGovernance: build.query<Pia.Governance, number>({
        query: (id) => ({
          url: `details/buildings/${id}/governance`,
        }),
        transformResponse: (data: Pia.Governance) => {
          return data;
        },
        providesTags: (_result, _error, query) => {
          return [{ type: 'Governance', id: query }];
        },
      }),
      getAssetTransportAccessibility: build.query<
        Pia.TransportAccessibility,
        { type: 'dwellings' | 'buildings' | 'lots'; id: string | number }
      >({
        query: ({ type, id }) => ({
          url: `details/${type}/${id}/transport-accessibility`,
        }),
        transformResponse: (data: { transport_accessibility: Pia.TransportAccessibility }) => {
          return data.transport_accessibility;
        },
        providesTags: (_reuslt, _error, query) => {
          return [{ type: 'TransportationAccessibility', id: query.id, assetType: query.type }];
        },
      }),
    }),
  });

function getDemographicParams({
  cantonCode,
  municipalityNb,
}: {
  cantonCode?: string | number;
  municipalityNb?: number;
}) {
  if (!cantonCode && !municipalityNb) return undefined;
  if (cantonCode) return { cantonCode };
  else return { municipalityId: municipalityNb };
}

function getContextParams(cantonId?: string | number, municipalityId?: number | string) {
  if (!cantonId && !municipalityId) return undefined;
  if (cantonId) return { cantonId };
  else return { municipalityId };
}

export default assetApi;
