import { useMemo } from 'react';
import { PHeader } from '../helpers/types';
import TableCheckbox from './Checkbox';

export default function HeaderSelect<T>({ table }: PHeader<T>) {
  const isSomeSelected = table.getIsSomeRowsSelected() || table.getIsSomePageRowsSelected();
  const isAllSelected = table.getIsAllRowsSelected();
  const state = table.getState();
  const toggle = table.toggleAllRowsSelected;

  const selected = useMemo(
    () =>
      Object.keys(state.rowSelection || [])
        .filter((key) => state.rowSelection[key])
        .map((key) => key),
    [state.rowSelection]
  );

  const isSomeSelectedInOtherPage = useMemo(
    () => !isSomeSelected && !isAllSelected && !!selected.length,
    [isAllSelected, isSomeSelected, selected.length]
  );

  // TODO: Use Mantine Checkbox and change its design
  return (
    <TableCheckbox
      isChecked={isAllSelected}
      isIndeterminate={isSomeSelected || isSomeSelectedInOtherPage}
      onClick={() => toggle()}
    />
  );
}
