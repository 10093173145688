import { useEffect, useMemo, useState } from 'react';
import { CColumn, TTableRef } from '@/components/Table4/helpers/types';
import useGetTableStateFromRef from './useGetStateFromRef';

export default function useGetColumns<T>(tableRef: TTableRef<T>) {
  const table = tableRef.current;
  const state = useGetTableStateFromRef(tableRef);

  const [allColumns, setAllColumns] = useState<CColumn<T>[]>([]);
  const [hideableColumns, setHideableColumns] = useState<CColumn<T>[]>([]);
  const [pinnableColumns, setPinnableColumns] = useState<CColumn<T>[]>([]);
  const [orderableColumns, setOrderableColumns] = useState<CColumn<T>[]>([]);
  const [sotableColumns, setSotableColumns] = useState<CColumn<T>[]>([]);

  const columns = table?.getAllFlatColumns();

  useEffect(() => {
    setAllColumns(columns || []);
    setHideableColumns(columns?.filter((col) => col.getCanHide()) || []);
    setPinnableColumns(columns?.filter((col) => col.getCanPin()) || []);
    setOrderableColumns(columns?.filter((col) => !col.getIsPinned()) || []);
    setSotableColumns(columns?.filter((col) => col.getCanSort()) || []);
  }, [columns, state]);

  const result = useMemo(
    () => ({
      hideableColumns,
      allColumns,
      pinnableColumns,
      sotableColumns,
      orderableColumns,
    }),
    [hideableColumns, allColumns, pinnableColumns, sotableColumns, orderableColumns]
  );

  return result;
}
