import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import API from '../types';
import kratosApi from '@/features/kratos';
import deepMerge from '@/features/helpers/deepMerge';

interface InitialState {
  deltaT: API.VariationPreferences;
}

const initialState: InitialState = { deltaT: {} };

export const deltaTPreferencesSlice = createSlice({
  name: 'variation_preferences',
  initialState,
  reducers: {
    updateVariationPreference(state, action: PayloadAction<API.VariationPreferences>) {
      state.deltaT = deepMerge(state.deltaT, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      kratosApi.endpoints.getVariationPreferences.matchFulfilled,
      (state, action) => {
        state.deltaT = action.payload;
      }
    );
  },
});

export const selectVariationPreference = (state: RootState) => state.variation_preferences.deltaT;

export const variationPreferencesReducer = deltaTPreferencesSlice.reducer;
