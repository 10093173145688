import EllipsisText from '@/components/EllipsisText';
import { formatDate } from '@/features/helpers/numbers';

type IProps = {
  reportDate?: string | Date | null;
  isAnnual?: boolean | null;
};

export default function ReportDateBadge({ reportDate, isAnnual }: IProps) {
  if (!reportDate) return null;
  return (
    <div className="date-badge">
      <EllipsisText
        label={
          isAnnual
            ? 'funds.composition.annual_report_date'
            : 'funds.composition.semi_annual_report_date'
        }
      />
      <p className="data">{formatDate(reportDate)}</p>
    </div>
  );
}
