import { useMemo } from 'react';
import { CRow } from '@/components/Table4/helpers/types';

export default function useRowSelect<T>(row: CRow<T>) {
  const canExpand = row.getCanExpand();
  const isSelected = row.getIsSelected();
  const canSelect = row.getCanSelect();
  const canSelectSubRows = row.getCanSelectSubRows();
  const canMultiSelect = row.getCanMultiSelect();
  const _isAllSubRowsSelected = row.getIsAllSubRowsSelected();
  const _isSomeSelected = row.getIsSomeSelected();

  const toggleSelected = useMemo(() => row.toggleSelected, [row]);

  const isAllSubRowsSelected = useMemo(
    () => (canExpand && row.subRows?.length ? _isAllSubRowsSelected : isSelected),
    [canExpand, row.subRows?.length, _isAllSubRowsSelected, isSelected]
  );

  const isSomeSelected = useMemo(
    () => (canExpand ? !isAllSubRowsSelected && _isSomeSelected : false),
    [canExpand, isAllSubRowsSelected, _isSomeSelected]
  );

  const options = useMemo(
    () => ({
      isSelected,
      canSelect,
      canSelectSubRows,
      toggleSelected,
      isSomeSelected,
      canMultiSelect,
      isAllSubRowsSelected,
    }),
    [
      isSelected,
      canSelect,
      canSelectSubRows,
      toggleSelected,
      isSomeSelected,
      canMultiSelect,
      isAllSubRowsSelected,
    ]
  );

  return options;
}
