import { ActionIcon, Tooltip } from '@mantine/core';
import { OverviewActionsProps } from '../type';

type IProps = {
  actions?: Array<OverviewActionsProps>;
  type: 'dwellings' | 'buildings' | 'lots' | 'funds' | 'portfolios';
  id: number | string;
};

export default function ActionsBadge({ actions, type, id }: IProps) {
  if (!actions?.length) return null;

  return (
    <span className="actions">
      {actions.map((item, index) => (
        <span className="action" key={index}>
          <Tooltip withinPortal label={item.label} key={item.label} disabled={!item.enabled}>
            <ActionIcon
              onClick={() => item.function?.({ type, id })}
              variant="transparent"
              size="xs"
              disabled={!item.enabled}
            >
              {item.icon}
            </ActionIcon>
          </Tooltip>
        </span>
      ))}
    </span>
  );
}
