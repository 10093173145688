import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import API from '../types';
import kratosApi from './../../kratos';

interface InitialState {
  popup: API.NotificationPreferences;
}

const initialState: InitialState = { popup: {} };

export const popupPreferencesSlice = createSlice({
  name: 'notification_preferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      kratosApi.endpoints.getNotificationPreferences.matchFulfilled,
      (state, action) => {
        state.popup = action.payload;
      }
    );
  },
});

export const selectNotificationPopupPreference = (state: RootState) =>
  state.notification_preferences.popup;

export const notificationPreferencesReducer = popupPreferencesSlice.reducer;
