import { TableSort } from '@/components/Funds/Detail/AssetList/type';
import { SortingState } from '@tanstack/react-table';
import notEmpty from './notEmpty';

export function currentSortToTableSort(currents?: SortingState): TableSort[] | undefined {
  if (!currents) return [];
  return currents
    .map((current) => {
      const { id, desc } = current;
      if (!id) return;
      const sort: TableSort = { column: id, order: desc ? 'DESC' : 'ASC' };
      return sort;
    })
    .filter(notEmpty);
}

export function tableSortsToCurrentSort(tableSorts?: TableSort[]): SortingState {
  if (!tableSorts) return [];
  return tableSorts
    .map((tableSort) => {
      const { column, order } = tableSort;
      if (!column) return;
      const sort: SortingState[number] = { id: column, desc: order === 'DESC' };
      return sort;
    })
    .filter(notEmpty);
}
