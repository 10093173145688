import { useCallback } from 'react';
import { PCell } from '../helpers/types';
import useRowSelect from '../hooks/useRowSelect';
import TableCheckbox from './Checkbox';

export default function RowSelect<T>({ row }: PCell<T>) {
  const { toggleSelected, isAllSubRowsSelected, isSomeSelected } = useRowSelect(row);

  const onClick = useCallback(() => toggleSelected(), [toggleSelected]);

  // TODO: Use Mantine Checkbox and change its design
  return (
    <TableCheckbox
      isChecked={isAllSubRowsSelected}
      onClick={onClick}
      isIndeterminate={isSomeSelected}
    />
  );
}
