import '@/scss/components/DoubleRange.css';
import { Input, NumberInput, Transition } from '@mantine/core';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerInput, DateValue } from '@mantine/dates';
import '@mantine/dates/styles.css';

export type DoubleRangeProps = {
  label?: string;
  selectedMin?: string | number;
  selectedMax?: string | number;
  withoutNumberFormatter?: boolean;
  onChange: (range: [string | number | undefined, string | number | undefined]) => unknown;
  isDate?: boolean;
  setFilterStatus?: (active?: boolean, visible?: boolean) => void;
  reloadInit?: number;
};

function DoubleRangeNotMemoized({
  label,
  onChange,
  withoutNumberFormatter,
  selectedMin,
  selectedMax,
  isDate,
  setFilterStatus,
  reloadInit,
}: DoubleRangeProps) {
  const { t } = useTranslation();
  const [rangeValue, setRangeValue] = useState<
    [Date | number | undefined, Date | number | undefined]
  >([
    typeof selectedMin === 'string' ? new Date(selectedMin) : selectedMin,
    typeof selectedMax === 'string' ? new Date(selectedMax) : selectedMax,
  ]);

  useEffect(() => {
    if (selectedMin !== undefined || selectedMax !== undefined) setFilterStatus?.(true, undefined);
    else setFilterStatus?.(false, undefined);
    setRangeValue([
      typeof selectedMin === 'string' ? new Date(selectedMin) : selectedMin,
      typeof selectedMax === 'string' ? new Date(selectedMax) : selectedMax,
    ]);
    // reload the selectedMin Max
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadInit]);

  const onClear = useCallback(() => {
    setRangeValue([undefined, undefined]);
    onChange([undefined, undefined]);
    setFilterStatus?.(false, false);
  }, [onChange, setFilterStatus]);

  const error = useMemo(() => {
    if (rangeValue[0] && rangeValue[1])
      if (rangeValue[0] > rangeValue[1]) return t('double_range_input.error_msg');
    if (withoutNumberFormatter) {
      if (
        (typeof rangeValue[0] === 'number' && (rangeValue[0] > 9999 || rangeValue[0] < 1000)) ||
        (typeof rangeValue[1] === 'number' && (rangeValue[1] > 9999 || rangeValue[1] < 1000))
      )
        return t('double_range_input.year_error_msg');
    }
    return '';
  }, [rangeValue, t, withoutNumberFormatter]);

  const updateValues = useCallback(
    (value: DateValue | string | number, isleft: boolean) => {
      const newValue = typeof value === 'string' ? undefined : value;
      const newValuePair: [Date | number | undefined, Date | number | undefined] = isleft
        ? [newValue ?? undefined, rangeValue[1]]
        : [rangeValue[0], newValue ?? undefined];
      setRangeValue(newValuePair);
      const serializedPair: [string | number | undefined, string | number | undefined] = [
        newValuePair[0] instanceof Date ? newValuePair[0].toISOString() : newValuePair[0],
        newValuePair[1] instanceof Date ? newValuePair[1].toISOString() : newValuePair[1],
      ];
      onChange(serializedPair);
      if (newValuePair[0] !== undefined || newValuePair[1] !== undefined)
        setFilterStatus?.(true, true);
      else setFilterStatus?.(false, false);
    },
    [onChange, rangeValue, setFilterStatus]
  );

  return (
    <div className="double-range">
      <Input.Wrapper label={label || ''} error={error}>
        <div className="double-range-input">
          {isDate ? (
            <div>
              <DatePickerInput
                value={(rangeValue[0] as Date) ?? null}
                valueFormat="DD/MM/YYYY"
                placeholder={t('double_range_input.start')}
                onChange={(value) => updateValues(value, true)}
              />
            </div>
          ) : (
            <NumberInput
              value={(rangeValue[0] as number) ?? ''}
              stepHoldDelay={500}
              onChange={(value) => updateValues(value, true)}
              hideControls
              stepHoldInterval={100}
              placeholder={t('double_range_input.min')}
              decimalSeparator="."
              thousandSeparator={withoutNumberFormatter ? '' : '’'}
            />
          )}
          {isDate ? (
            <div>
              <DatePickerInput
                value={(rangeValue[1] as Date) ?? null}
                valueFormat="DD/MM/YYYY"
                placeholder={t('double_range_input.end')}
                onChange={(value) => updateValues(value, false)}
              />
            </div>
          ) : (
            <NumberInput
              value={(rangeValue[1] as number) ?? ''}
              stepHoldDelay={500}
              hideControls
              onChange={(value) => updateValues(value, false)}
              stepHoldInterval={100}
              placeholder={t('double_range_input.max')}
              decimalSeparator="."
              thousandSeparator={withoutNumberFormatter ? '' : '’'}
            />
          )}
        </div>
      </Input.Wrapper>
      <Transition
        mounted={rangeValue[0] !== undefined || rangeValue[1] !== undefined}
        transition="scale-y"
        duration={500}
        timingFunction="ease"
        keepMounted
      >
        {(transitionStyle) => (
          <span className="double-range-clear-button" onClick={onClear} style={transitionStyle}>
            {t('double_range_input.clear')}
          </span>
        )}
      </Transition>
    </div>
  );
}

const DoubleRange = memo(DoubleRangeNotMemoized, (prev, next) => {
  // only when reloadInit change the double range needs to be re-render
  // otherwise double range handle the changes internally
  const { reloadInit: prevReloadInit } = prev;
  const { reloadInit: nextReloadInit } = next;
  const noRerender = prevReloadInit === nextReloadInit;
  return noRerender;
});

export default DoubleRange;
