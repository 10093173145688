import { useCallback, useEffect, useRef, useState } from 'react';
import { TrackerTypes } from '@/pages/WatchListPage';
import { ScrollArea } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';
import WatchlistScrollPage from './ScrollPage';

export default function WatchlistScroll({ types }: { types: TrackerTypes }) {
  const scrollRef = useRef(null);
  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(0);
  const { ref, entry } = useIntersection({
    root: scrollRef.current,
    threshold: 1,
  });

  const setLastFail = useCallback(
    (page: number) => setMaxPage((current) => (current && current < page ? current : page)),
    []
  );

  useEffect(() => {
    const isIntersecting = (entry as { isIntersecting?: boolean } | null)?.isIntersecting;
    if (isIntersecting) {
      setPage((currentPage) => (maxPage ? currentPage : currentPage + 1));
    }
  }, [entry, maxPage]);

  return (
    <div className="dropdown">
      <ScrollArea.Autosize
        className="dropdown-scroll-area"
        viewportRef={scrollRef}
        scrollbarSize={8}
        scrollHideDelay={1000}
        type="auto"
        offsetScrollbars={true}
      >
        <div className="overviews-scroll-area">
          {[...Array(maxPage || page)].map((_x, index) => (
            <WatchlistScrollPage
              key={`dropdown-scroll-${index}`}
              page={index + 1}
              type={types}
              setLastFail={setLastFail}
            />
          ))}
        </div>
        <div ref={ref}></div>
      </ScrollArea.Autosize>
    </div>
  );
}
