import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import kratosApi from '../features/kratos';
import apiWrapper from '../features/api';
import kratosReducer from '../features/kratos/kratosSlice';
import enterpriseReducer from '../features/keto/ketoSlice';
import { usersReducer } from '../features/api/apiSlice';
import piaApi from '../features/pia/piaApi';
import { screenerReducer } from '../features/api/screener/slice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { notificationsReducer } from '../features/api/notificationSlice';
import { screenerPresetReducer } from '../features/api/screener/presetSlice';
import navigationReducer from '@/features/api/navigationSlice';
import { tablePreferencesReducer } from '@/features/api/preferences/tableSlice';
import { viewPreferencesReducer } from '@/features/api/preferences/viewSlice';
import { valuationPreferencesReducer } from '@/features/api/preferences/valuationSlice';
import { watchlistReducer } from '@/components/Watchlist/watchlistSlice';
import { notificationPreferencesReducer } from '@/features/api/preferences/notificationPopupSlice';
import { graphPreferencesReducer } from '@/features/api/preferences/graphSlice';
import { variationPreferencesReducer } from '@/features/api/preferences/variationSlice';

const combinedReducers = combineReducers({
  kratos: kratosReducer,
  enterprise: enterpriseReducer,
  users: usersReducer,
  screener: screenerReducer,
  screenerPreset: screenerPresetReducer,
  notifications: notificationsReducer,
  notification_preferences: notificationPreferencesReducer,
  table_preferences: tablePreferencesReducer,
  graph_preferences: graphPreferencesReducer,
  variation_preferences: variationPreferencesReducer,
  valuation_preferences: valuationPreferencesReducer,
  view_preferences: viewPreferencesReducer,
  navigation: navigationReducer,
  watchlist: watchlistReducer,
  [piaApi.reducerPath]: piaApi.reducer,
  [apiWrapper.reducerPath]: apiWrapper.reducer,
  [kratosApi.reducerPath]: kratosApi.reducer,
});

export type InitialState = ReturnType<typeof combinedReducers>;

export const store = configureStore({
  reducer: (state: InitialState | undefined, action) => {
    if (action.type === 'logout') {
      state = undefined;
    }
    return combinedReducers(state, action);
  },
  middleware: (getDefault) =>
    getDefault()
      .concat(apiWrapper.middleware)
      .concat(kratosApi.middleware)
      .concat(piaApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
