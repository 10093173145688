import { useTranslation } from 'react-i18next';

type IProps = {
  ownerLegalForms?:
    | 'foundation'
    | 'listed_fund'
    | 'unlisted_fund'
    | 'real_estate_firm'
    | 'mixed'
    | null;
};

export default function OwnerBadge({ ownerLegalForms }: IProps) {
  const { t } = useTranslation();

  if (!ownerLegalForms) return null;

  return (
    <div className="asset-special-status object-list-item-upper-section">
      {t(`overview.property.${ownerLegalForms}`)}
    </div>
  );
}
