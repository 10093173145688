import {
  EstimatedBadge,
  ListedBadge,
  ReportedBadge,
  MarketBadge,
  OfficialBadge,
  ComputedBadge,
} from '../Badge';
import { FilterListData } from './type';
import DoubleRange from '../DoubleRange';
import { PresetCriterias, ScreenerType } from '@/features/api/screener/screenerTypes';

export const informations: Partial<Record<ScreenerType, FilterListData[]>> = {
  funds: [
    { path: 'launch_date', withoutUnit: true, isDate: false, withoutNumberFormatter: true },
    { path: 'last_report_date', withoutUnit: true, isDate: true, withoutNumberFormatter: true },
    { path: 'm_dividend_date', withoutUnit: true, isDate: true, withoutNumberFormatter: true },
    { path: 'r_units_outstanding', withoutUnit: true },
    { path: 'r_total_area' },
    { path: 'r_commercial_spaces_area' },
    { path: 'r_residential_area' },
    { path: 'total_area' },
    { path: 'commercial_spaces_area' },
    { path: 'residential_area' },
    { path: 'lots_nb', withoutUnit: true },
    { path: 'building_groups_nb', withoutUnit: true },
    { path: 'buildings_nb', withoutUnit: true },
    { path: 'dwellings_nb', withoutUnit: true },
    { path: 'name', withoutUnit: true, nonFilterable: true },
    { path: 'isin', withoutUnit: true, nonFilterable: true },
    { path: 'bloomberg_ticker', withoutUnit: true, nonFilterable: true },
  ],
  buildings: [
    {
      path: 'construction_year',
      header: 'construction_date',
      withoutUnit: true,
      withoutNumberFormatter: true,
    },
    { path: 'dwellings_nb', withoutUnit: true },
    { path: 'floors_nb', withoutUnit: true },
    { path: 'ground_area' },
    { path: 'residential_area' },
    { path: 'commercial_area' },
    { path: 'total_area' },
    { path: 'r_rental_space' },
  ],
  transactions: [
    {
      path: 'construction_year',
      header: 'construction_date',
      withoutUnit: true,
      withoutNumberFormatter: true,
    },
    { path: 'dwellings_nb', withoutUnit: true },
    { path: 'floors_nb', withoutUnit: true },
    { path: 'ground_area' },
    { path: 'total_area' },
  ],
  dwellings: [
    {
      path: 'construction_year',
      header: 'construction_date',
      withoutUnit: true,
      withoutNumberFormatter: true,
    },
    { path: 'floor', withoutUnit: true },
    { path: 'rooms_nb', withoutUnit: true },
    { path: 'area' },
    { path: 'estimated_area' },
  ],
};
export const accountings: Partial<Record<ScreenerType, FilterListData[]>> = {
  funds: [
    { path: 'r_rental_income' },
    { path: 'r_building_expenses' },
    { path: 'r_capital_distribution' },
    { path: 'r_construction_and_works_fees' },
    { path: 'r_estimated_liquidation_taxes' },
    { path: 'r_maintenance_and_repairs_expenses' },
    { path: 'r_profit_and_capital_taxes' },
    { path: 'r_property_management_fees' },
    { path: 'r_property_tax' },
    { path: 'r_purchase_and_sale_fees' },
    { path: 'r_realised_capital_gains_and_losses' },
    { path: 'r_realised_income' },
    { path: 'r_retained_earnings' },
    { path: 'r_revenue_from_postal_and_bank_accounts' },
    { path: 'r_short_term_interest_bearing_mortgages' },
    { path: 'r_short_term_liabilities' },
    { path: 'r_taxes' },
    { path: 'r_total_cash' },
    { path: 'r_total_dividend' },
    { path: 'r_total_expenses' },
    { path: 'r_total_income' },
    { path: 'r_total_interest' },
    { path: 'r_total_liabilities' },
    { path: 'r_total_properties' },
    { path: 'r_total_revenue' },
    { path: 'r_unrealised_capital_gains_and_losses' },
    { path: 'r_valuation_and_auditing_expenses' },
    { path: 'r_valuation_expenses' },
    { path: 'r_absolute_custodian_bank_fees' },
    { path: 'r_absolute_management_fees' },
    { path: 'r_administration_of_buildings' },
    { path: 'r_auditing_expenses' },
    { path: 'r_custodian_bank_fees' },
    { path: 'r_management_fees' },
    { path: 'r_share_buyback_commissions' },
    { path: 'r_share_issue_commissions' },
    { path: 'r_ter_gav' },
    { path: 'r_ter_mv' },
    { path: 'r_ter_nav' },
    { path: 'c_current_liabilities' },
    { path: 'c_operating_profit' },
    { path: 'r_dividend_distribution' },
    { path: 'r_dividend_from_capital_gains' },
    { path: 'r_dividend_from_direct_rental_income' },
    { path: 'r_dividend_from_indirect_rental_income' },
    { path: 'r_long_term_interest_bearing_mortgages' },
    { path: 'r_long_term_liabilities' },
    { path: 'r_mortgage_interest' },
    { path: 'r_negative_interest' },
    { path: 'r_net_assets_at_beginning_of_the_financial_year' },
    { path: 'r_net_assets_before_estimated_liquidation_taxes' },
    { path: 'r_net_income' },
    { path: 'r_net_income_available_for_distribution' },
    { path: 'r_nopat' },
    { path: 'r_ordinary_income_carried_forward_from_previous_financial_year' },
    { path: 'r_other_assets' },
    { path: 'r_other_expenses' },
    { path: 'r_other_interest' },
    { path: 'r_other_long_term_liabilities' },
    { path: 'r_other_revenue' },
    { path: 'r_other_short_term_liabilities' },
    { path: 'r_gav_before_distribution' },
    { path: 'r_market_cap' },
    { path: 'r_nav_before_distribution' },
    { path: 'r_tga_after_distribution' },
    { path: 'r_tga_before_distribution' },
    { path: 'r_tna_after_distribution' },
    { path: 'r_tna_before_distribution' },
    { path: 'r_unrealised_gains_and_losses_including_var_est_liq_tax' },
  ],
};
export const financials: Partial<Record<ScreenerType, FilterListData[]>> = {
  funds: [
    { path: 'c_debt_ratio' },
    { path: 'c_current_ratio' },
    { path: 'c_ebit_margin' },
    { path: 'c_loan_to_value' },
    { path: 'c_net_initial_yield' },
    { path: 'c_payout_ratio' },
    { path: 'c_pe_ratio' },
    { path: 'c_price_to_book_value' },
    { path: 'c_roa' },
    { path: 'c_roce' },
    { path: 'c_roe' },
    { path: 'c_total_cash_per_share' },
    { path: 'r_debt_ratio' },
    { path: 'r_dividend_yield' },
    { path: 'c_earnings_per_share' },
    { path: 'c_revenue_per_share' },
    { path: 'r_ebit' },
    { path: 'r_ebit_margin' },
    { path: 'c_median_cost_of_debt' },
    { path: 'c_median_maturity' },
    { path: 'r_payout_ratio' },
    { path: 'r_performance' },
    { path: 'r_rental_losses' },
    { path: 'r_roa' },
    { path: 'r_roce' },
    { path: 'r_roe' },
    { path: 'r_roi' },
    { path: 'r_roic' },
    { path: 'r_wault' },
    { path: 'c_weighted_average_cost_of_debt' },
    { path: 'c_weighted_average_maturity' },
    { path: 'm_dividend' },
    { path: 'r_capital_employed' },
    { path: 'net_rent' },
    { path: 'potential_total_income' },
  ],
  buildings: [
    { path: 'potential_net_rent' },
    { path: 'potential_net_rent_per_m2' },
    { path: 'potential_market_value' },
    { path: 'potential_market_value_per_m2' },
    { path: 'potential_charges' },
    { path: 'potential_charges_per_m2' },
    { path: 'potential_roa' },
    { path: 'real_net_rent' },
    { path: 'real_market_value' },
    { path: 'real_charges' },
    { path: 'real_roa' },
    { path: 'r_market_value' },
    { path: 'r_real_rent' },
    { path: 'r_target_rent' },
    { path: 'r_cost_price' },
    { path: 'r_gross_yield' },
  ],
  dwellings: [
    { path: 'potential_net_rent' },
    { path: 'potential_net_rent_per_m2' },
    { path: 'potential_market_value' },
    { path: 'potential_market_value_per_m2' },
    { path: 'potential_charges' },
    { path: 'potential_charges_per_m2' },
    { path: 'potential_roa' },
    { path: 'real_net_rent' },
    { path: 'real_charges' },

    { path: 'potential_net_rent_monthly', nonFilterable: true },
    { path: 'potential_charges_monthly', nonFilterable: true },
    { path: 'real_net_rent_monthly', nonFilterable: true },
    { path: 'real_charges_monthly', nonFilterable: true },
  ],
};
export const performances: Partial<Record<ScreenerType, FilterListData[]>> = {
  funds: [
    { path: 'm_market_cap' },
    { path: 'r_share_price' },
    { path: 'c_gav_after_distribution' },
    { path: 'c_nav_after_distribution' },
    { path: 'r_agio_at_year_end' },
    { path: 'c_total_agio_at_year_end' },
    { path: 'c_current_agio_after_distribution' },
    { path: 'c_current_agio_compounded' },
    { path: 'c_gav_compounded' },
    { path: 'c_nav_compounded' },
    { path: 'c_tga_compounded' },
    { path: 'c_tna_compounded' },
    { path: 'c_total_current_agio_after_distribution' },
    { path: 'c_total_current_agio_compounded' },
    { path: 'alpha', withoutUnit: true },
    { path: 'beta', withoutUnit: true },
    { path: 'm_share_price' },
    { path: 'm_share_price_var' },
    { path: 'm_dividend_yield' },
    { path: 'fifty_two_weeks_high' },
    { path: 'fifty_two_weeks_low' },
    { path: 'five_days_avg_volume', withoutUnit: true },
    { path: 'five_years_avg_dividend_yield' },
    { path: 'five_years_return' },
    { path: 'c_three_years_total_return' },
    { path: 'c_five_years_total_return' },
    { path: 'high' },
    { path: 'hist_volatility_180_days', withoutUnit: true },
    { path: 'hist_volatility_250_days', withoutUnit: true },
    { path: 'hist_volatility_30_days', withoutUnit: true },
    { path: 'hist_volatility_90_days', withoutUnit: true },
    { path: 'implied_volatility', withoutUnit: true },
    { path: 'last' },
    { path: 'low' },
    { path: 'mtd_net_return' },
    { path: 'one_month_avg_volume', withoutUnit: true },
    { path: 'one_month_return' },
    { path: 'one_year_avg_volume', withoutUnit: true },
    { path: 'one_year_return' },
    { path: 'open' },
    { path: 'qtd_net_return' },
    { path: 'r2', withoutUnit: true },
    { path: 'six_months_avg_volume', withoutUnit: true },
    { path: 'six_months_return' },
    { path: 'three_months_avg_volume', withoutUnit: true },
    { path: 'three_months_return' },
    { path: 'three_years_return' },
    { path: 'turnover' },
    { path: 'volume', withoutUnit: true },
    { path: 'ytd_net_return' },
    { path: 'one_year_total_return' },
    { path: 'three_years_total_return' },
    { path: 'five_years_total_return' },
    { path: 'vwap', nonFilterable: true },
  ],
};
export const esgs: Partial<Record<ScreenerType, FilterListData[]>> = {
  funds: [
    { path: 'environmental', withoutUnit: true },
    { path: 'social', withoutUnit: true },
    { path: 'governance', withoutUnit: true },
    { path: 'esg', withoutUnit: true },
    { path: 'total_co2' },
    { path: 'energy_intensity' },
  ],
};
export const locations: Partial<Record<ScreenerType, FilterListData[]>> = {
  lots: [
    { path: 'sector_nb', withoutUnit: true, nonFilterable: true },
    { path: 'nb', withoutUnit: true, nonFilterable: true },
  ],
  buildings: [
    { path: 'type', withoutUnit: true, nonFilterable: true },
    { path: 'zip', withoutUnit: true, nonFilterable: true },
    { path: 'egid', withoutUnit: true, nonFilterable: true },
  ],
  transactions: [
    { path: 'municipality_nb', withoutUnit: true, nonFilterable: true },
    { path: 'zip', withoutUnit: true, nonFilterable: true },
    { path: 'egid', withoutUnit: true, nonFilterable: true },
  ],
  dwellings: [{ path: 'zip', withoutUnit: true, nonFilterable: true }],
};
export const dimensions: Partial<Record<ScreenerType, FilterListData[]>> = {
  lots: [{ path: 'area' }, { path: 'buildings_nb', withoutUnit: true }],
};
export const energies: Partial<Record<ScreenerType, FilterListData[]>> = {
  buildings: [
    { path: 'co2', header: 'estimated_co2_emission_per_m2' },
    { path: 'idc', header: 'estimated_idc_per_m2' },
    { path: 'solar_existing' },
  ],
  dwellings: [
    { path: 'co2', header: 'estimated_co2_emission_per_m2' },
    { path: 'idc', header: 'estimated_idc_per_m2' },
  ],
};
export const transactions: Partial<Record<ScreenerType, FilterListData[]>> = {
  transactions: [
    { path: 'tx_year', header: 'date', withoutUnit: true, withoutNumberFormatter: true },
    { path: 'tx_price', header: 'value' },
  ],
};

const allFiltersBuilding = [
  ...(informations.buildings ?? []).map(({ path, header }) => header ?? path),
  ...(financials.buildings ?? []).map(({ path, header }) => header ?? path),
  ...(locations.buildings ?? []).map(({ path, header }) => header ?? path),
  ...(energies.buildings ?? []).map(({ path, header }) => header ?? path),
  'region',
  'canton',
  'locality',
  'labels',
  'energy_source_heating',
  'energy_source_water',
  'fund_legal_forms',
  'fund_names',
];
const allFiltersTransaction = [
  ...(informations.transactions ?? []).map(({ path, header }) => header ?? path),
  ...(locations.transactions ?? []).map(({ path, header }) => header ?? path),
  ...(transactions.transactions ?? []).map(({ path, header }) => header ?? path),
  'region',
  'canton',
  'locality',
  'seller_name',
  'buyer_name',
];
const allFiltersDwelling = [
  ...(informations.dwellings ?? []).map(({ path, header }) => header ?? path),
  ...(financials.dwellings ?? []).map(({ path, header }) => header ?? path),
  ...(locations.dwellings ?? []).map(({ path, header }) => header ?? path),
  ...(energies.dwellings ?? []).map(({ path, header }) => header ?? path),
  'region',
  'canton',
  'locality',
  'labels',
  'energy_source_heating',
  'energy_source_water',
];
const allFiltersPlot = [
  ...(locations.lots ?? []).map(({ path, header }) => header ?? path),
  ...(dimensions.lots ?? []).map(({ path, header }) => header ?? path),
  'region',
  'canton',
  'municipality_name',
];
const allFiltersFund = [
  ...(informations.funds ?? []).map(({ path, header }) => header ?? path),
  ...(accountings.funds ?? []).map(({ path, header }) => header ?? path),
  ...(financials.funds ?? []).map(({ path, header }) => header ?? path),
  ...(performances.funds ?? []).map(({ path, header }) => header ?? path),
  ...(esgs.funds ?? []).map(({ path, header }) => header ?? path),
  'legal_form',
  'type',
  'environmental_rating',
  'social_rating',
  'governance_rating',
  'esg_rating',
  'legal_form',
];

const defaultInvisibleFiltersBuilding = allFiltersBuilding.filter(
  (header) =>
    ![
      'street_short',
      'locality',
      'zip',
      'type',
      'floors_nb',
      'dwellings_nb',
      'residential_area',
      'commercial_area',
      'total_area',
      'potential_market_value',
      'potential_net_rent',
      'potential_roa',
      'estimated_co2_emission_per_m2',
    ].includes(header)
);
const defaultInvisibleFiltersTransaction = allFiltersTransaction.filter(
  (header) =>
    !['street_short', 'date', 'zip', 'locality', 'value', 'buyer_name', 'seller_name'].includes(
      header
    )
);
const defaultInvisibleFiltersDwelling = allFiltersDwelling.filter(
  (header) =>
    ![
      'ewid',
      'construction_date',
      'floor',
      'rooms_nb',
      'area',
      'potential_net_rent',
      'potential_charges',
      'potential_market_value',
    ].includes(header)
);
const defaultInvisibleFiltersPlot = allFiltersPlot.filter(
  (header) =>
    !['nb', 'sector_nb', 'municipality_name', 'area', 'built_area', 'buildings_nb'].includes(header)
);
const defaultInvisibleFiltersFund = allFiltersFund.filter(
  (header) =>
    ![
      'short_name',
      'isin',
      'm_market_cap',
      'r_rental_income',
      'c_debt_ratio',
      'total_co2',
      'esg',
      'total_area',
      'building_groups_nb',
      'buildings_nb',
      'dwellings_nb',
      'last_report_date',
    ].includes(header)
);

export const defaultColToIgnore: Record<ScreenerType, string[]> = {
  buildings: defaultInvisibleFiltersBuilding,
  transactions: defaultInvisibleFiltersTransaction,
  dwellings: defaultInvisibleFiltersDwelling,
  lots: defaultInvisibleFiltersPlot,
  funds: defaultInvisibleFiltersFund,
};

export const defaultAllCols: Record<ScreenerType, string[]> = {
  buildings: allFiltersBuilding,
  transactions: allFiltersTransaction,
  dwellings: allFiltersDwelling,
  lots: allFiltersPlot,
  funds: allFiltersFund,
};

export function getFilterList(
  data: FilterListData[],
  originalSelections: PresetCriterias,
  setSelections: (partOfSelections: PresetCriterias) => void,
  reloadSlice: number
) {
  return data.map(
    ({ path, header, isDate, withoutNumberFormatter, withoutUnit, nonFilterable }) => {
      const alreadyVisible = !originalSelections.columnsToIgnore?.includes(header ?? path) ?? true;
      return {
        header: header ?? path,
        visible: alreadyVisible,
        activated: nonFilterable
          ? false
          : originalSelections[(path + '_min') as keyof PresetCriterias] !== undefined ||
            originalSelections[(path + '_max') as keyof PresetCriterias] !== undefined,
        // area for lot and dwelling should have different translation
        label:
          path === 'nb' && originalSelections.type === 'lots'
            ? `table_header.lot_nb`
            : `table_header.${header ?? path}`,
        unit: withoutUnit ? undefined : `units.${path}`,
        sourceBadge: getSourceBadge(header ?? path),
        renderChild: nonFilterable
          ? undefined
          : (setFilterStatus: (active?: boolean, visible?: boolean) => void) => (
              <DoubleRange
                setFilterStatus={setFilterStatus}
                selectedMin={
                  originalSelections[(path + '_min') as keyof PresetCriterias] as
                    | string
                    | number
                    | undefined
                }
                selectedMax={
                  originalSelections[(path + '_max') as keyof PresetCriterias] as
                    | string
                    | number
                    | undefined
                }
                onChange={(range) => {
                  setSelections({
                    [(path + '_min') as keyof PresetCriterias]: range[0],
                    [(path + '_max') as keyof PresetCriterias]: range[1],
                  });
                }}
                withoutNumberFormatter={withoutNumberFormatter ?? false}
                isDate={isDate ?? false}
                reloadInit={reloadSlice}
              />
            ),
      };
    }
  );
}

const otherECols = ['total_co2', 'energy_intensity'];
const otherMCols = [
  'c_three_years_total_return',
  'c_five_years_total_return',
  'fifty_two_weeks_high',
  'fifty_two_weeks_low',
  'five_days_avg_volume',
  'five_years_avg_dividend_yield',
  'five_years_return',
  'high',
  'hist_volatility_180_days',
  'hist_volatility_250_days',
  'hist_volatility_30_days',
  'hist_volatility_90_days',
  'implied_volatility',
  'last',
  'low',
  'mtd_net_return',
  'one_month_avg_volume',
  'one_month_return',
  'one_year_avg_volume',
  'one_year_return',
  'open',
  'qtd_net_return',
  'r2',
  'six_months_avg_volume',
  'six_months_return',
  'three_months_avg_volume',
  'three_months_return',
  'three_years_return',
  'turnover',
  'volume',
  'ytd_net_return',
  'one_year_total_return',
  'three_years_total_return',
  'five_years_total_return',
  'vwap',
];
const otherRCols = [
  'building_groups_nb',
  'net_rent',
  'date', // for tx
  'value', // for tx
  'buyer_name',
  'seller_name',
  'labels',
];
const otherOCols = [
  'legal_form',
  'launch_date',
  'fund_type',
  'name',
  'bloomberg_ticker',
  'isin',
  'buildings_nb',
  'dwellings_nb',
  'lots_nb',
  'last_report_date',
  'total_area',
  'commercial_spaces_area',
  'residential_area',
  'esg',
  'environmental',
  'social',
  'governance',
  'esg_rating',
  'environmental_rating',
  'social_rating',
  'governance_rating',

  'locality',
  'region',
  'zip',
  'canton',
  'municipality_name',
  'egid',
  'fund_names',
  'fund_legal_forms',
  'type',
  'construction_date',
  'floors_nb',
  'dwellings_nb',
  'ground_area',
  'residential_area',
  'energy_source_heating',
  'energy_source_water',
  'solar_existing',

  // for plot
  'sector_nb',
  'nb',
  'area',

  // for dwellings
  'floor',
  'rooms_nb',
];
const otherCCols = ['total_area', 'commercial_area', 'commercial_spaces_area', 'alpha', 'beta'];

export function getSourceBadge(path: string) {
  if (path.startsWith('r_') || otherRCols.includes(path)) return <ReportedBadge useAbbreviation />;
  if (path.startsWith('m_') || otherMCols.includes(path)) return <MarketBadge useAbbreviation />;
  if (path.startsWith('c_') || otherCCols.includes(path)) return <ComputedBadge useAbbreviation />;
  if (path.startsWith('real_')) return <ListedBadge useAbbreviation />;
  if (path.startsWith('potential_') || path.startsWith('estimated_') || otherECols.includes(path))
    return <EstimatedBadge useAbbreviation />;
  if (otherOCols.includes(path)) return <OfficialBadge useAbbreviation />;
  return undefined;
}
