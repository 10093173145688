import { useEffect, useState, lazy, useCallback, useMemo } from 'react';
import { Link, NonIndexRouteObject, useParams } from 'react-router-dom';
import { selectSession } from '@/features/kratos/kratosSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';

import UseNotifications from '@/features/helpers/useNotifications';
import { selectEnterprise, unSetEnterprise } from '@/features/keto/ketoSlice';
import Fallback from '@/layouts/Fallback';

import kratosApi from '@/features/kratos';
import { store } from '@/app/store';
import i18n from '@/i18n';

import { t } from 'i18next';

import UseBoardingRoutes from './BoardingRoutes';
import apiWrapper, { useLazyGetEnterpriseQuery } from '@/features/api';

const NoRightPortfolios = lazy(() => import('@/components/Portfolios/NoRight'));
const NoRightFunds = lazy(() => import('@/components/Funds/NoRight'));
import Redirector from './Redirector';
import notificationsApi from '@/features/api/notifications';
import UseIsDev from '@/features/helpers/useIsDev';
import UseIsAdmin from '@/features/helpers/useIsAdmin';

import { RTKResult } from '@/features/helpers/fiiErrorParser';
import { updateScreenerPresetId } from '@/features/api/screener/presetSlice';
const MainLayout = lazy(() => import('@/layouts/MainLayout'));
const ManagementLayout = lazy(() => import('@/layouts/Management/ManagementLayout'));

const PortfolioPageItem = lazy(() => import('@/pages/PortfolioPageItem'));
const Preference = lazy(() => import('@/pages/Settings/Preference'));
const RealEstatePage = lazy(() => import('@/pages/RealEstatePage'));

const NotFoundPage = lazy(() => import('@/pages/NotFoundPage'));
const DashboardPage = lazy(() => import('@/pages/DashboardPage'));
const AssetPage = lazy(() => import('@/pages/AssetPage'));
const MapPage = lazy(() => import('@/pages/MapPage'));
const FundsPage = lazy(() => import('@/pages/FundsPage'));
const FundsDetail = lazy(() => import('@/pages/FundDetail'));
const MyPortfolioPage = lazy(() => import('@/pages/MyPortfolioPage'));
const WatchListPage = lazy(() => import('@/pages/WatchListPage'));
const Verify = lazy(() => import('@/pages/flows/Verify'));
const Profile = lazy(() => import('@/components/Profile'));
const Security = lazy(() => import('@/pages/Settings/Security'));
const BackOfficeEnterprise = lazy(() => import('@/pages/BackOffice/BackOfficeEnterprise'));
const Organization = lazy(() => import('@/pages/Settings/Organization'));
const CreateOrganization = lazy(() => import('@/pages/Settings/CreateOrganization'));
const UpdateOrganization = lazy(() => import('@/pages/Settings/UpdateOrganization'));
const InternalCollaborators = lazy(() => import('@/pages/Settings/InternalCollaborators'));
const ExternalCollaborators = lazy(() => import('@/pages/Settings/ExternalCollaborators'));
const RedirectToSetting = lazy(() => import('@/pages/Settings/RedirectToSetting'));
const Seats = lazy(() => import('@/pages/Settings/Seat'));
const CompaniesPage = lazy(() => import('@/pages/Companies/CompaniesPage'));
const PrivateRoute = lazy(() => import('@/routes/PrivateRoute'));
const BoardingLayout = lazy(() => import('@/layouts/BoardingLayout'));
const ShowRecovery = lazy(() => import('@/pages/flows/ShowRecovery'));
const Connections = lazy(() => import('@/pages/Settings/Connections/Connections'));
export default function UseRoutes() {
  const [routes, setRoutes] = useState<NonIndexRouteObject[]>([
    {
      path: '*',
      element: <Fallback />,
    },
  ]);
  const session = useAppSelector(selectSession);
  const notification = UseNotifications();
  const enterprise = useAppSelector(selectEnterprise);
  const dispatch = useAppDispatch();

  const [getEnterprise, { data: enterpriseData }] = useLazyGetEnterpriseQuery();
  const [getIsAdmin, { isAdmin, checkFinished: checkAdminFinished }] = UseIsAdmin();
  const [getIsDev, { isDev, checkFinished: checkDevFinished }] = UseIsDev(
    enterpriseData || undefined
  );

  useEffect(() => {});

  const [logOut] = kratosApi.useLazyLogoutQuery();
  const boardingRoutes = UseBoardingRoutes();
  const [getLang, { currentData: languageData }] = kratosApi.useLazyGetLangQuery();
  const [initNotification] = notificationsApi.useLazyGetNotificationsQuery();
  const [prefetchTablePreference] = kratosApi.useLazyGetTablePreferencesQuery();
  const [prefetchNotificationPreference] = kratosApi.useLazyGetNotificationPreferencesQuery();
  const [prefetchGraphPreference] = kratosApi.useLazyGetGraphPreferencesQuery();
  const [prefetchVariationPreference] = kratosApi.useLazyGetVariationPreferencesQuery();
  const [prefetchValuationPreference] = kratosApi.useLazyGetValuationPreferencesQuery();
  const [getHasRecovery, { data: recoveryData }] = kratosApi.useLazyCheckRecoveryCodesQuery();
  const [getViewPreferences] = kratosApi.useLazyGetViewPreferencesQuery();
  const [getPresetPreferences] = kratosApi.useLazyGetPresetPreferencesQuery();

  const [isReadyInFulfilled, setReady] = useState(false);

  useEffect(() => {
    if (checkAdminFinished && !isAdmin) getEnterprise();
  }, [checkAdminFinished, getEnterprise, isAdmin, isReadyInFulfilled]);

  const { data: canAccessPortfolios } = apiWrapper.useGetCanQuery(
    {
      namespace: 'services',
      object: 'portfolios',
      relation: 'get',
    },
    { skip: session?.status !== 'fulfilled' }
  );
  const { data: canAccessFunds } = apiWrapper.useGetCanQuery(
    {
      namespace: 'services',
      object: 'funds',
      relation: 'get',
    },
    { skip: session?.status !== 'fulfilled' }
  );

  const emptyCacheAndLogOut = useCallback(() => {
    store.dispatch({ type: 'logout' });
    logOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // We call it too fast after login
    // Setting a debounce
    const timerId = setTimeout(async () => {
      if (session?.status === 'fulfilled') {
        await Promise.allSettled([
          getIsAdmin(),
          getIsDev(),
          // True is for preferCacheValue
          getLang(undefined, true),
          getHasRecovery(),
          prefetchTablePreference(),
          prefetchNotificationPreference(),
          prefetchGraphPreference(),
          prefetchVariationPreference(),
          prefetchValuationPreference(),
          initNotification(),
          getViewPreferences(),
          getPresetPreferences().then((result) => {
            const { data } = result as RTKResult<{ asset?: string; fund?: string }>;
            if (data?.asset) dispatch(updateScreenerPresetId({ type: 'asset', id: data.asset }));
            if (data?.fund) dispatch(updateScreenerPresetId({ type: 'fund', id: data.fund }));
          }),
        ]);
        setReady(true);
      } else setReady(false);
    }, 500);
    return () => clearTimeout(timerId);
  }, [
    getIsAdmin,
    session,
    getLang,
    getHasRecovery,
    getViewPreferences,
    prefetchTablePreference,
    prefetchGraphPreference,
    prefetchVariationPreference,
    prefetchValuationPreference,
    prefetchNotificationPreference,
    initNotification,
    getIsDev,
    getPresetPreferences,
    dispatch,
  ]);

  useEffect(() => {
    if (session?.status === 'failed' && session?.boolStatus) {
      notification.show({
        title: t('notification.terminated_session.title'),
        message: t('notification.terminated_session.message'),
        color: 'yellow',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const settingsRoute = useMemo(
    () => ({
      path: '/settings',
      element: <ManagementLayout logoutFunc={emptyCacheAndLogOut} />,
      children: [
        {
          path: '/settings/profile',
          element: <Profile />,
        },
        {
          path: '/settings/security',
          element: <Security />,
        },
        {
          path: '/settings/preferences',
          element: <Preference />,
        },
        (isDev || isAdmin) && {
          path: '/settings/connections',
          element: <Connections />,
        },
        !isAdmin && {
          path: '/settings/organization',
          element: <Organization />,
        },
        !isAdmin && {
          path: '/settings/seats',
          element: <Seats />,
        },
        {
          path: '/settings/collaborators',
          element: isAdmin ? <InternalCollaborators /> : <ExternalCollaborators />,
        },
        {
          path: '/settings/*',
          element: <NotFoundPage />,
        },
      ].filter(Boolean) as NonIndexRouteObject[],
    }),
    [emptyCacheAndLogOut, isDev, isAdmin]
  );

  useEffect(() => {
    if (
      isReadyInFulfilled &&
      enterprise.state === 'fullfilled' &&
      checkAdminFinished &&
      recoveryData
    ) {
      if (!recoveryData.is_code_existent) {
        setRoutes([
          {
            path: '/',
            element: <BoardingLayout />,
            children: [
              {
                path: '/',
                element: <ShowRecovery />,
              },
              {
                path: '*',
                element: <Redirector />,
              },
            ],
          },
        ]);
        return;
      }

      const settingRoutesObject: NonIndexRouteObject[] = [
        settingsRoute,
        isAdmin && {
          path: '/administration',
          element: <ManagementLayout logoutFunc={emptyCacheAndLogOut} />,
          children: [
            {
              path: '/administration/companies',
              handle: {
                crumb: () => (
                  <Link to="/administration/companies">
                    {t('administration.breadcrumb.companies')}
                  </Link>
                ),
              },
              children: [
                {
                  index: true,
                  element: <CompaniesPage />,
                },
                {
                  path: '/administration/companies/create',
                  element: <CreateOrganization />,
                  handle: {
                    crumb: () => (
                      <Link to="/administration/companies/create">
                        {t('administration.breadcrumb.create_company')}
                      </Link>
                    ),
                  },
                },
                {
                  path: '/administration/companies/:id',
                  handle: {
                    crumb: () => {
                      // eslint-disable-next-line react-hooks/rules-of-hooks
                      const { id = ':id' } = useParams();
                      return (
                        <Link to={`/administration/companies/${id}`}>
                          {t('administration.breadcrumb.details')}
                        </Link>
                      );
                    },
                  },
                  children: [
                    {
                      element: <BackOfficeEnterprise />,
                      index: true,
                    },
                    {
                      path: '/administration/companies/:id/edit',
                      element: <UpdateOrganization />,
                      handle: {
                        crumb: () => {
                          // eslint-disable-next-line react-hooks/rules-of-hooks
                          const { id = ':id' } = useParams();
                          return (
                            <Link to={`/administration/companies/${id}/edit`}>
                              {t('administration.breadcrumb.edit')}
                            </Link>
                          );
                        },
                      },
                    },
                  ],
                },
              ],
            },

            {
              path: '/administration/requests',
              element: <NotFoundPage />,
            },
            {
              path: '/administration/*',
              element: <NotFoundPage />,
            },
          ],
        },
      ].filter(Boolean) as NonIndexRouteObject[];
      const nonIndexRoutesObject: NonIndexRouteObject[] =
        session.identity?.verifiable_addresses?.some((address) => address.verified)
          ? [
              {
                path: '/',
                element: <MainLayout />,
                children: [
                  {
                    path: '/',
                    element: <DashboardPage />,
                  },
                  {
                    path: '/asset/:type/:id',
                    element: <AssetPage />,
                  },
                  {
                    path: '/real-estate',
                    element: <RealEstatePage />,
                  },
                  {
                    path: '/map',
                    element: <MapPage />,
                    children: [
                      {
                        path: '/map/:type/:id',
                        element: <MapPage />,
                      },
                    ],
                  },
                  {
                    path: '/watchlist',
                    element: <WatchListPage />,
                  },
                  {
                    path: '/portfolios',
                    element: (
                      <PrivateRoute
                        errorTitle="Portfolios"
                        whitelist={canAccessPortfolios || false}
                        fallback={<NoRightPortfolios />}
                      />
                    ),
                    children: [
                      {
                        path: '/portfolios',
                        element: <MyPortfolioPage />,
                      },
                      {
                        path: '/portfolios/:id',
                        element: <PortfolioPageItem />,
                      },
                    ],
                  },
                  {
                    path: '/funds',
                    element: (
                      <PrivateRoute
                        errorTitle="Funds"
                        whitelist={canAccessFunds || false}
                        fallback={<NoRightFunds />}
                      />
                    ),
                    children: [
                      {
                        path: '/funds',
                        element: <FundsPage />,
                      },
                      {
                        path: '/funds/:id',
                        element: <FundsDetail />,
                      },
                    ],
                  },
                  {
                    path: '/verification',
                    element: <Verify />,
                  },
                  {
                    path: '/self-service/verification',
                    element: <Verify />,
                  },
                  {
                    path: '*',
                    element: <NotFoundPage />,
                  },
                ],
              },
              ...settingRoutesObject,
            ]
          : [
              {
                path: '/verification',
                element: <Verify />,
              },
              {
                path: '/self-service/verification',
                element: <Verify />,
              },
              {
                path: '*',
                element: <RedirectToSetting pathToRedirect={'/settings/profile'} />,
              },
              ...settingRoutesObject,
            ];

      setRoutes(nonIndexRoutesObject);
    }
  }, [
    session,
    isReadyInFulfilled,
    isAdmin,
    settingsRoute,
    checkAdminFinished,
    checkDevFinished,
    recoveryData,
    canAccessFunds,
    canAccessPortfolios,
    emptyCacheAndLogOut,
    enterprise,
  ]);

  useEffect(() => {
    if (
      session &&
      ['require-totp', 'require-totp-setup', 'failed', 'recovery', 'onboarding'].includes(
        session?.status
      )
    )
      setRoutes(boardingRoutes as NonIndexRouteObject[]);
  }, [session, boardingRoutes]);

  useEffect(() => {
    if (session?.status === 'logout')
      setRoutes([
        {
          path: '*',
          element: <Fallback />,
        },
      ]);
  }, [session]);

  useEffect(() => {
    if (
      isReadyInFulfilled &&
      ['unpaid', 'endTrial', 'noSlot', 'ended'].includes(enterprise.state)
    ) {
      setRoutes([
        settingsRoute,
        {
          path: '*',
          element: <RedirectToSetting pathToRedirect={'/settings/seats'} />,
        },
      ]);
    } else if (isReadyInFulfilled && ['outScope', 'slotOutScope'].includes(enterprise.state)) {
      if (window.location.pathname === '/') dispatch(unSetEnterprise());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprise, session, settingsRoute, isAdmin, isReadyInFulfilled]);

  useEffect(() => {
    if (languageData) {
      i18n.changeLanguage(languageData?.language);
    }
  }, [languageData]);

  return routes;
}
