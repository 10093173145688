import {
  de_default
} from "./chunk-ABR22S6Y.mjs";
import {
  en_default
} from "./chunk-PAP4P5YL.mjs";
import {
  fr_default
} from "./chunk-W7BNJX4R.mjs";

// src/frontend/index.ts
var frontend_default = { en: en_default, fr: fr_default, de: de_default };

export {
  frontend_default
};
