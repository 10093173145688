import { ObjectListItemType } from '@/components/ObjectList/type';
import { Building, Floorplan } from '@carbon/icons-react';
import { useMemo } from 'react';
import { getDescriptionFromOverview, getLegendsFromOverview } from '@/pages/sections/helper';
import { Skeleton, Transition } from '@mantine/core';
import UseToggleToTracker from '../CommonActions/UseToggleToTracker';
import UseAddToPortfolio from '../CommonActions/UseAddToPortfolio';
import apiWrapper from '@/features/api';
import assetApi from '@/features/pia/sections';
import ObjectListItem from '../ObjectList/ObjectListItem';

interface AssetProps {
  type: 'dwellings' | 'buildings' | 'lots';
  id: number;
}
export default function WatchlistScrollElement({ type, id }: AssetProps) {
  const { data: overview, isFetching, isLoading } = assetApi.useGetAssetOverviewQuery({ type, id });
  const { data: canAccessPortfolios } = apiWrapper.useGetCanQuery({
    namespace: 'services',
    object: 'portfolios',
    relation: 'get',
  });

  const toggleTracker = UseToggleToTracker({
    assetId: id,
    isTracked: true,
    asset: type === 'buildings' ? 'building' : 'dwelling',
  });
  const addToPortfolio = UseAddToPortfolio({
    assetId: id,
    asset: type === 'buildings' ? 'building' : 'dwelling',
  });

  const actions = useMemo(() => {
    if (canAccessPortfolios) return [toggleTracker, addToPortfolio];
    else return [toggleTracker];
  }, [canAccessPortfolios, toggleTracker, addToPortfolio]);

  const icon = useMemo(() => {
    switch (type) {
      case 'dwellings':
        return <Floorplan />;
      case 'buildings':
        return <Building />;
      default:
        return <Building />;
    }
  }, [type]);

  const content: ObjectListItemType = {
    type: type,
    icon: icon,
    id: id,
    title: overview?.label || '',
    legends: !overview ? [] : getLegendsFromOverview(overview),
    description: type === 'lots' || !overview ? [] : getDescriptionFromOverview(overview),
    status: {
      translation: overview?.status_translation || null,
      id: overview?.status || null,
    },
    funds_legal_form: overview?.funds_legal_form || null,
  };

  return (
    <Skeleton visible={isLoading}>
      <Transition mounted={!isFetching} transition="scale-y">
        {(styles) => (
          <div style={styles}>
            <ObjectListItem item={content} actions={actions} />
          </div>
        )}
      </Transition>
    </Skeleton>
  );
}
