import { formatFullFloat } from '@/features/helpers/numbers';
import VariationBadge from '../../VariationBadge';
import { MarketValueBadgeProps } from '../type';
import UnitBadge from '../../UnitBadge';

export default function MarketValueBadge(props: MarketValueBadgeProps) {
  const { value, suffix, variation } = props;

  if (!value) return null;

  return (
    <div className="market-value-badge">
      {variation && <VariationBadge {...variation} invertColors={false} />}
      <p>{formatFullFloat(value)}</p>
      <UnitBadge unit={suffix} />
    </div>
  );
}
