import EllipsisText from '@/components/EllipsisText';
import { UseParseError } from '@/features/helpers/fiiErrorParser';
import UseNotifications from '@/features/helpers/useNotifications';
import portfolioApi from '@/features/pia/portfolios';
import { Checkmark, Close, Edit } from '@carbon/icons-react';
import { ActionIcon } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

type IProps = {
  title: string;
  type: 'dwellings' | 'buildings' | 'lots' | 'funds' | 'portfolios';
  id: number | string;
  isTitleEditable?: boolean;
};

export default function TitleBadge(props: IProps) {
  const { title, type, id, isTitleEditable } = props;

  const location = useLocation();
  const focusTrapRef = useFocusTrap();
  const [setNewName, { isSuccess: isNameChangeDone, error: nameChangeError }] =
    portfolioApi.useUpdatePortfolioMutation();
  const [editOn, setEditOn] = useState(false);
  const [inputTitle, setInputTitle] = useState(title);
  const path = useMemo(
    () => `/${['funds', 'portfolios'].includes(type) ? '' : 'asset/'}${type}/${id}`,
    [type, id]
  );
  const notification = UseNotifications();
  const { message: nameErrorMessage } = UseParseError(nameChangeError);
  const { t } = useTranslation();

  useEffect(() => {
    if (nameErrorMessage && !isNameChangeDone) {
      notification.show({
        title: t('common.error'),
        message: nameErrorMessage,
        color: 'yellow',
      });
      setInputTitle(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameErrorMessage]);

  useEffect(() => {
    if (isNameChangeDone) {
      notification.show({
        title: t('action.portfolios.name.change_success.title'),
        message: t('action.portfolios.name.change_success.message'),
        color: 'green',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNameChangeDone]);

  useEffect(() => {
    if (title) setInputTitle(title);
  }, [title]);

  const handleNameChange = useCallback(
    <T extends React.ChangeEvent<HTMLFormElement> & React.MouseEvent<HTMLButtonElement>>(
      event: T
    ) => {
      event.preventDefault();
      setNewName({ id, title: inputTitle, buildings: [], dwellings: [] });
      setEditOn(false);
    },
    [id, inputTitle, setNewName]
  );

  useEffect(() => {
    if (title) setInputTitle(title);
  }, [title]);
  const titleLink = useMemo(() => {
    if (location.pathname === path) {
      return (
        <div className="title">
          <span className="title-main">
            {isTitleEditable ? (
              <>
                {editOn ? (
                  <>
                    <form onSubmit={handleNameChange}>
                      <input
                        ref={focusTrapRef}
                        className="title-main-edit"
                        value={inputTitle}
                        onChange={(e) => setInputTitle(e.target.value)}
                      />

                      <ActionIcon onClick={handleNameChange} component="button" formAction="submit">
                        <Checkmark />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          setEditOn(false);
                          setInputTitle(title);
                        }}
                      >
                        <Close />
                      </ActionIcon>
                    </form>
                  </>
                ) : (
                  <>
                    <span>{inputTitle}</span>
                    <ActionIcon onClick={() => setEditOn(true)}>
                      <Edit />
                    </ActionIcon>
                  </>
                )}
              </>
            ) : (
              <span>{inputTitle}</span>
            )}
          </span>
        </div>
      );
    } else {
      return (
        <Link to={path} className="title">
          <span className="title-main">
            <EllipsisText label={title} noTranslation={true} />
          </span>
        </Link>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, path, title, editOn, inputTitle]);

  return titleLink;
}
