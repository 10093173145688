import { OfficialBadge } from '@/components/Badge';
import { ContextFormater, Formater } from '@/components/helpers/Financials/helpers';
import { ObjectListItemType } from '@/components/ObjectList/type';
import {
  ItemBoolType,
  ItemMapType,
  ItemStringType,
  ItemTrendType,
  Trend,
} from '@/components/Widget/types';
import biFilter from '@/features/helpers/bifilter';
import {
  formatFullInt,
  formatPercentToString,
  getYear,
  isNumber,
} from '@/features/helpers/numbers';
import Pia from '@/features/pia/type';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { calProps } from './Valuation';

export function formatAdmin({
  admin,
  type,
}: {
  admin?: Pia.Admin;
  type: 'lots' | 'buildings' | 'dwellings';
}) {
  switch (type) {
    case 'lots':
      return [
        { label: 'asset.description.lots.city', value: admin?.city },
        { label: 'asset.description.lots.land_use', value: admin?.land_use },
      ];
    case 'buildings':
      return [
        { label: 'asset.description.buildings.egid', value: admin?.egid?.toString() },
        { label: 'asset.description.buildings.city', value: admin?.city },
        { label: 'asset.description.buildings.official_nb', value: admin?.official_nb },
        { label: 'asset.description.buildings.land_use', value: admin?.land_use },
        { label: 'asset.description.buildings.status', value: admin?.status },
        { label: 'asset.description.buildings.class', value: admin?.class },
        { label: 'asset.description.buildings.category', value: admin?.category },
      ];
    case 'dwellings':
      return [
        { label: 'asset.description.dwellings.ewid', value: admin?.ewid?.toString() },
        { label: 'asset.description.dwellings.city', value: admin?.city },
        { label: 'asset.description.dwellings.land_use', value: admin?.land_use },
        { label: 'asset.description.dwellings.status', value: admin?.status },
        { label: 'asset.description.dwellings.class', value: admin?.class },
        { label: 'asset.description.dwellings.category', value: admin?.category },
      ];
  }
}

export function formatPlotIdentification({
  description,
  admin,
  isLoading,
  t,
  marker,
  markerIsLoading,
}: {
  description?: Pia.GeneralDescription;
  admin?: Pia.Admin;
  isLoading: boolean;
  t: TFunction<'translation', undefined>;
  marker?: Pia.Marker;
  markerIsLoading: boolean;
}) {
  return [
    {
      title: t('asset_page.description.tile.description'),
      data: [
        {
          label: 'asset.description.lots.egrid',
          value: description?.egrid,
        },
        {
          label: 'asset.description.lots.municipality',
          value: description?.municipality,
        },
        {
          label: 'asset.description.lots.number',
          value: description?.number,
        },
        {
          label: 'asset.description.lots.sector',
          value: description?.sector?.toString(),
        },
        {
          label: 'asset.description.lots.surface',
          value: description?.surface,
          suffix: 'units.area',
        },
      ] as (ItemBoolType | ItemStringType)[],
      isLoading: isLoading,
      span: 2,
    },
    {
      title: t('asset_page.description.tile.usage'),
      data: [
        {
          label: 'asset.description.lots.land_use',
          value: admin?.land_use,
        },
        {
          label: 'asset.description.lots.built_up_area',
          value: formatFullInt(description?.built_up?.area),
          suffix: 'units.built_up.area',
        },
        {
          label: 'asset.description.lots.built_up_ratio',
          value: formatPercentToString(description?.built_up?.ratio || null),
          suffix: 'units.built_up.ratio',
        },
      ] as (ItemBoolType | ItemStringType)[],
      isLoading: isLoading,
      span: 2,
    },
    {
      title: t('asset_page.description.tile.location'),
      data: [
        {
          type: 'map',
          data: marker?.coordinates ? [marker] : undefined,
        },
      ] as ItemMapType[],
      isLoading: markerIsLoading,
    },
  ];
}

export function formatGeneralDescription({
  description,
  type,
  id,
  isParent,
}: {
  description?: Pia.GeneralDescription;
  type: 'lots' | 'buildings' | 'dwellings';
  id: number | string | undefined;
  isParent: boolean;
}) {
  switch (type) {
    case 'lots':
      return [
        {
          label: 'asset.description.lots.egrid',
          value: description?.egrid,
          link: isParent ? `/asset/lots/${id}` : undefined,
        },
        {
          label: 'asset.description.lots.municipality',
          value: description?.municipality,
        },
        {
          label: 'asset.description.lots.number',
          value: description?.number,
        },
        {
          label: 'asset.description.lots.sector',
          value: description?.sector,
        },
        {
          label: 'asset.description.lots.surface',
          value: description?.surface,
          suffix: 'units.surface',
        },
        {
          label: 'asset.description.lots.built_up_area',
          value: formatFullInt(description?.built_up?.area || null),
          suffix: 'units.built_up.area',
        },
        {
          label: 'asset.description.lots.built_up_ratio',
          value: formatPercentToString(description?.built_up?.ratio || null),
          suffix: 'units.built_up.ratio',
        },
      ];
    case 'buildings': {
      // filter if parent. then dont show optional
      return biFilter(
        [
          {
            label: 'asset.description.dwellings.egid',
            value: description?.egid,
            link: `/asset/buildings/${id}`,
            optional: true,
          },
          {
            label: 'asset.description.buildings.construction_date',
            value: description?.construction_date
              ? getYear(description.construction_date)
              : undefined,
          },
          {
            label: 'asset.description.buildings.floors_nb',
            value: formatFullInt(description?.floors_nb),
          },
          {
            label: 'asset.description.buildings.dwellings_nb',
            value: formatFullInt(description?.dwellings_nb),
          },
        ],
        (item) => {
          if (!isParent && item.optional) return false;
          else return true;
        }
      )[0];
    }
    case 'dwellings': {
      return [
        {
          label: 'asset.description.buildings.construction_date',
          value: description?.construction_date
            ? getYear(description.construction_date)
            : undefined,
        },
        {
          label: 'asset.description.dwellings.rooms_nb',
          value: formatFullInt(description?.rooms_nb),
        },
        {
          label: 'asset.description.dwellings.floor',
          value: formatFullInt(description?.floor),
        },
        {
          label: 'asset.description.dwellings.surface',
          value: formatFullInt(description?.area),
          suffix: 'units.area',
        },
      ];
    }
  }
}

export function formatGeometry(
  description: Pia.GeneralDescription | undefined,
  type: 'lots' | 'buildings' | 'dwellings'
) {
  switch (type) {
    case 'lots':
      return [];
    case 'buildings':
      return [
        {
          label: 'asset.description.buildings.residential_area',
          value: formatFullInt(description?.residential_area),
          suffix: 'units.residential_area',
        },
        {
          label: 'asset.description.buildings.commercial_area',
          value: formatFullInt(description?.commercial_area),
          suffix: 'units.commercial_area',
        },
        {
          label: 'asset.description.buildings.total_area',
          value: formatFullInt(description?.total_area),
          suffix: 'units.total_area',
          style: 'bold',
        },
        {
          label: 'asset.description.buildings.area',
          value: formatFullInt(description?.area),
          suffix: 'units.area',
        },
        {
          label: 'asset.description.buildings.volume',
          value: formatFullInt(description?.volume),
          suffix: 'units.volume',
        },
      ];
    case 'dwellings':
      return [];
  }
}

export function formatAmenities(
  amenities: Pia.Amenities | undefined,
  type: 'lots' | 'buildings' | 'dwellings'
) {
  switch (type) {
    case 'lots':
      return [];
    case 'buildings':
      return [
        {
          label: 'asset.description.amenities.buildings.fallout_shelter',
          value: amenities?.fallout_shelter,
        },
        {
          label: 'asset.description.amenities.buildings.imputed_elevator',
          value: amenities?.imputed_elevator,
        },
        {
          label: 'asset.description.amenities.buildings.imputed_bike_room',
          value: amenities?.imputed_bike_room,
        },
        {
          label: 'asset.description.amenities.buildings.imputed_near_highway',
          value: amenities?.imputed_near_highway,
        },
        {
          label: 'asset.description.amenities.buildings.imputed_near_transports',
          value: amenities?.imputed_near_transports,
        },
        {
          label: 'asset.description.amenities.buildings.imputed_cachet',
          value: amenities?.imputed_cachet,
        },
      ];
    case 'dwellings':
      return [
        {
          label: 'asset.description.amenities.dwellings.imputed_cellar',
          value: amenities?.imputed_cellar,
        },
        {
          label: 'asset.description.amenities.dwellings.imputed_balcony',
          value: amenities?.imputed_balcony,
        },
        {
          label: 'asset.description.amenities.dwellings.imputed_attique',
          value: amenities?.imputed_attique,
        },
        {
          label: 'asset.description.amenities.dwellings.imputed_mansarde',
          value: amenities?.imputed_mansarde,
        },
      ];
  }
}

export function formatCivilStatus(status: Pia.Status | undefined) {
  return [
    {
      label: 'asset_page.social.demographic.civil_status.household',
      value: status?.household ? formatFullInt(status?.household) : '-',
      badge: <OfficialBadge useAbbreviation />,
      suffix: 'units.household_size',
    },
    {
      label: 'asset_page.social.demographic.civil_status.marriage',
      value: formatPercentToString(status?.marriage || null),
      badge: <OfficialBadge useAbbreviation />,
      suffix: 'units.marriage',
    },
    {
      label: 'asset_page.social.demographic.civil_status.divorce',
      value: formatPercentToString(status?.divorce || null),
      badge: <OfficialBadge useAbbreviation />,
      suffix: 'units.divorce',
    },
  ];
}

export function formatDemographicAges(ages: Pia.Ages) {
  const formatAges = ages['ages-by-gender'] || ages.ages_by_gender || [];
  const preAges = formatAges.map((item) => {
    return {
      key: item.group.replace('year old', '').replace(' or more', '+'),
      [item.label1]: item.value1,
      [item.label2]: item.value2,
    };
  });

  const maxPopulation = formatAges.reduce((acc, { value1, value2 }) => acc + value1 + value2, 0);
  const group1 = formatAges[0]?.label1;
  const group2 = formatAges[0]?.label2;
  const data = preAges.map((item) => {
    return {
      key: item.key,
      value1: Number(((item[group1] as number) / maxPopulation) * 100),
      value2: Number(((item[group2] as number) / maxPopulation) * 100),
    };
  });
  const sortData = data.sort(
    (group1, group2) =>
      Number(group2.key.split('-')[0].split('+')[0]) -
      Number(group1.key.split('-')[0].split('+')[0])
  );
  return { data: sortData, group1: group1, group2: group2 };
}

export function formatDemographicGender(gender: Pia.Gender) {
  const preGender = gender?.gender?.map((item) => {
    return { group: item.group, value: item.value1 };
  });
  return preGender || [];
}
export function formatDemographicForeigners(foreigner: Pia.Foreigners | undefined) {
  if (!foreigner?.citizenship) return [];
  const preForeigner = foreigner.citizenship.map((item) => {
    return { group: item.group, value: item.value1 };
  });
  return preForeigner;
}

export function getLegendsFromOverview(overview: Pia.Overview): ObjectListItemType['legends'] {
  const legends = [
    { type: 'surface', value: overview.area ?? overview?.total_area },
    { type: 'volume', value: overview.volume },
    { type: 'lots_nb', value: overview.lots_nb },
    { type: 'buildings_nb', value: overview.buildings_nb },
    { type: 'dwellings_nb', value: overview.dwellings_nb },
  ];
  const filteredLegends = legends.filter(({ value }) =>
    Boolean(value)
  ) as ObjectListItemType['legends'];
  return filteredLegends;
}

export function getDescriptionFromOverview(overview: Pia.Overview): Array<ItemTrendType> {
  const beforeFilter = [
    {
      label: 'asset_page.overview.description.market_value',
      trends: [
        {
          value: overview.market_value ? formatFullInt(overview.market_value) : null,
          diff: Number(overview.market_value_var),
          suffix: 'units.market_value',
        },
      ],
    },

    {
      label: 'asset_page.overview.description.net_rent',
      trends: [
        {
          value: overview.net_rent ? formatFullInt(overview.net_rent) : null,
          diff: Number(overview.net_rent_var),
          suffix: 'units.net_rent',
        },
      ],
    },
    {
      label: 'asset_page.overview.description.charges',
      trends: [
        {
          value: overview.charges ? formatFullInt(overview.charges) : null,
          diff: Number(overview.charges_var),
          color: 'reverse' as Trend['color'],
          suffix: 'units.charges',
        }, //reverse
      ],
    },
    {
      label: 'asset_page.overview.description.roa',
      trends: [
        {
          value: overview.roa ? formatPercentToString(overview.roa) : null,
          diff: Number(overview.roa_var),
          suffix: 'units.roa',
        },
      ],
    },
    {
      label: 'asset_page.overview.description.co2',
      trends: [
        {
          value: overview.co2_emissions !== null ? formatFullInt(overview.co2_emissions) : null,
          diff: Number(null),
          color: 'reverse' as Trend['color'],
          suffix: 'units.co2_m2',
        },
      ], // reverse
    },
    {
      label: 'asset_page.overview.description.idc',
      trends: [
        {
          value: overview.idc !== null ? formatFullInt(overview.idc) : null,
          diff: Number(null),
          color: 'reverse' as Trend['color'],
          suffix: 'units.idc_m2',
        },
      ], //reverse
    },
  ];

  const filteredDescriptions = beforeFilter.filter(({ trends }) =>
    trends.every(({ value }) => value !== null)
  );
  return filteredDescriptions;
}

export function calTotal(inputs: calProps) {
  const {
    type,
    notaryFee,
    registrationFee,
    transfertFee,
    managerFee,
    otherFeesPercent,
    otherFeesPlain,
    bs,
    valuation,
    otherValuation,
    marketPrice,
    residentialIncome,
    commercialIncome,
    otherIncome,
    netRent,
    capRate,
  } = inputs;
  const totIncome =
    type === 'dwellings'
      ? netRent
      : (residentialIncome || 0) + (commercialIncome || 0) + (otherIncome || 0);

  const feesPercent =
    (bs || 0) * ((notaryFee || 0) + (registrationFee || 0) + (transfertFee || 0)) +
    (managerFee || 0) +
    (otherFeesPercent || 0);
  const priceAndPercentFees = (marketPrice || 0) * (1 + feesPercent / 100);
  const additionalFees = (otherFeesPlain || 0) + (valuation || 0) + (otherValuation || 0);
  const qhTotal = marketPrice ? priceAndPercentFees + additionalFees : undefined;
  const capRatePP = capRate ? (totIncome || 0) / (capRate / 100) : marketPrice;
  const capRateTotal =
    typeof capRatePP === 'number'
      ? (capRatePP || 0) * (1 + feesPercent / 100) + additionalFees
      : undefined;
  return {
    qhTotal: typeof qhTotal === 'number' ? Math.round(qhTotal) : undefined,
    capRatePP: typeof capRatePP === 'number' ? Math.round(capRatePP) : undefined,
    capRateTotal: typeof capRateTotal === 'number' ? Math.round(capRateTotal) : undefined,
  };
}

export function formatGradient<T extends unknown>(
  t: TFunction<'translation', undefined>,
  isLoading: boolean,
  contexts?: Record<string, Pia.Context>,
  values?: T,
  valueInPercent?: boolean
) {
  return function (
    tuple:
      | [string, string, keyof T, string | undefined]
      | [string, string, keyof T, string | undefined, string | undefined]
      | [string, string, keyof T, string | undefined, string | undefined, number | undefined]
      | [
          string,
          string,
          keyof T,
          string | undefined,
          string | undefined,
          number | undefined,
          ReactNode | undefined,
        ]
  ) {
    const [contextKey, subjectKey, valueKey, unitKey, suffixKey, sigFigs, badge] = tuple;
    const subject = t(subjectKey);
    const unit = unitKey ? t(unitKey) : undefined;
    const suffix = suffixKey ? t(suffixKey) : undefined;
    const context = contexts?.[contextKey];
    return {
      subject,
      unit,
      suffix,
      value: valueInPercent ? Number(values?.[valueKey]) * 100 : values?.[valueKey],
      min: context?.lower_bound,
      max: context?.upper_bound,
      quartiles: [context?.q1, context?.q2, context?.q3],
      isLoading,
      sigFigs,
      badge,
    };
  };
}

export type HighlightLineTuple<T> =
  | [
      string,
      string,
      keyof T,
      string,
      string | undefined,
      string | undefined,
      undefined | Formater,
      'pts' | '%',
      boolean | undefined,
      ReactNode | undefined,
      number | undefined,
    ]
  | [
      string,
      string,
      keyof T,
      string,
      string | undefined,
      string | undefined,
      undefined | Formater,
      'pts' | '%',
      boolean | undefined,
      ReactNode | undefined,
      number | undefined,
      ContextFormater | undefined,
    ];

export function formatHighLightGradient<T extends unknown>(
  t: TFunction<'translation', undefined>,
  isLoading: boolean,
  contexts?: Record<string, Pia.Context | undefined>,
  source?: T,
  previous?: T
) {
  return function (tuple: HighlightLineTuple<T>) {
    const [
      contextKey,
      subjectKey,
      valueKey,
      descriptionKey,
      unitKey,
      suffixKey,
      formater,
      varUnits,
      invertColors,
      badge,
      sigFigs,
      contextFormater,
    ] = tuple;
    const subject = t(subjectKey);
    const unit = unitKey ? t(unitKey) : undefined;
    const suffix = suffixKey ? t(suffixKey) : undefined;
    const context = contexts?.[contextKey];
    const currentValue = source?.[valueKey];
    const oldValue = previous?.[valueKey];
    const value = currentValue && formater ? formater(currentValue) : currentValue;
    const diff =
      oldValue && currentValue && isNumber(oldValue) && isNumber(currentValue)
        ? currentValue - oldValue
        : undefined;
    const variation =
      varUnits === 'pts'
        ? diff
          ? diff * 100
          : undefined
        : diff && isNumber(oldValue) && (diff / oldValue) * 100;

    return {
      subject,
      unit,
      suffix,
      value: currentValue as string | number | null | undefined,
      displayedValue: (value === null || value === undefined ? value : `${value}`) as
        | string
        | number
        | null
        | undefined,
      min: contextFormater ? contextFormater(context?.lower_bound) : context?.lower_bound,
      max: contextFormater ? contextFormater(context?.upper_bound) : context?.upper_bound,
      quartiles: [
        contextFormater ? contextFormater(context?.q1) : context?.q1,
        contextFormater ? contextFormater(context?.q2) : context?.q2,
        contextFormater ? contextFormater(context?.q3) : context?.q3,
      ],
      isLoading,
      sigFigs,
      badge,
      formater: formater,
      description: descriptionKey ? t(descriptionKey) : undefined,
      variation:
        variation && varUnits
          ? {
              value: variation,
              invertColors,
              suffix: varUnits,
            }
          : undefined,
    };
  };
}
