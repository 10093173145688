import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { PresetCriterias, ScreenerType } from './screenerTypes';
import { defaultColToIgnore } from '@/components/Screener/helper';
import { defaultColOrder } from '@/pages/screener/helper';
import { defaultFirstCols } from '@/components/Screener/useScreenerTableConfiguration';

const initialState: {
  fund: Partial<Record<ScreenerType, PresetCriterias>> & {
    type: ScreenerType;
  };
  asset: Partial<Record<ScreenerType, PresetCriterias>> & {
    type: ScreenerType;
  };
} = {
  fund: {
    type: 'funds',
    funds: {
      type: 'funds',
      columnsToIgnore: defaultColToIgnore['funds'],
      sort: [],
      order: defaultColOrder['funds'],
      columnPinning: { left: ['select', ...defaultFirstCols['funds']], right: [] },
    },
  },
  asset: {
    type: 'buildings',
    buildings: {
      type: 'buildings',
      columnsToIgnore: defaultColToIgnore['buildings'],
      sort: [],
      order: defaultColOrder['buildings'],
      columnPinning: { left: ['select', ...defaultFirstCols['buildings']], right: [] },
    },
    transactions: {
      type: 'transactions',
      columnsToIgnore: defaultColToIgnore['transactions'],
      sort: [],
      order: defaultColOrder['transactions'],
      columnPinning: { left: ['select', ...defaultFirstCols['transactions']], right: [] },
    },
    dwellings: {
      type: 'dwellings',
      columnsToIgnore: defaultColToIgnore['dwellings'],
      sort: [],
      order: defaultColOrder['dwellings'],
      columnPinning: { left: ['select', ...defaultFirstCols['dwellings']], right: [] },
    },
    lots: {
      type: 'lots',
      columnsToIgnore: defaultColToIgnore['lots'],
      sort: [],
      order: defaultColOrder['lots'],
      columnPinning: { left: ['select', ...defaultFirstCols['lots']], right: [] },
    },
  },
};

function setState<K extends keyof T, T, V extends T[K]>({
  state,
  key,
  value,
}: {
  state: T;
  key: K;
  value: V;
}) {
  return (state[key] = value);
}

export const screenerSlice = createSlice({
  name: 'screener',
  initialState,
  reducers: {
    updateScreener: (
      state,
      action: PayloadAction<{ type: 'fund' | 'asset'; selections: PresetCriterias }>
    ) => {
      const type = action.payload.type;
      const selections = action.payload.selections;

      const assetType = selections.type ?? state[type].type;
      if (assetType !== state[type].type) state[type].type = assetType;

      const keys = Object.keys(selections) as Array<keyof PresetCriterias>;
      const stateToBeChanged = state[type][assetType] ?? {};

      keys.map((key) => setState({ state: stateToBeChanged, key, value: selections[key] }));
    },
    hardResetScreener: (state, action: PayloadAction<{ type: 'fund' | 'asset' }>) => {
      const type = action.payload.type;
      const assetType = state[type].type;

      state[type][assetType] = {
        type: assetType,
        columnsToIgnore: defaultColToIgnore[assetType],
        sort: [],
        sizing: {},
        order: defaultColOrder[assetType],
        columnPinning: { left: ['select', ...defaultFirstCols[assetType]], right: [] },
      };
    },
    setScreener: (
      state,
      action: PayloadAction<{ type: 'fund' | 'asset'; selections?: PresetCriterias }>
    ) => {
      const type = action.payload.type;
      const assetType = action.payload.selections?.type || state[type].type;
      if (assetType !== state[type].type) state[type].type = assetType;

      state[type][assetType] = action.payload.selections || {
        type: state[type].type,
        columnsToIgnore: defaultColToIgnore[assetType],
        sort: [],
        order: defaultColOrder[assetType],
        columnPinning: { left: ['select', ...defaultFirstCols[assetType]], right: [] },
      };
    },
  },
});

export const selectFundCriterias = (state: RootState) => state.screener.fund.funds ?? {};
export const selectAssetCriterias = (state: RootState) => {
  const assetType = state.screener.asset.type;
  return state.screener.asset[assetType] ?? {};
};

export const { updateScreener, hardResetScreener, setScreener } = screenerSlice.actions;

export const screenerReducer = screenerSlice.reducer;
