export interface WithLink {
  label?: string;
  link: string;
}

export function isLink(obj: unknown): obj is WithLink {
  const tmp = obj as WithLink;
  return tmp && (!tmp.label || typeof tmp.label === 'string') && typeof tmp.link === 'string';
}

export default isLink;
