import API from '../api/types';
import * as AdminHandlers from './administration';
import { addNotification, removeNotification } from '../api/notificationSlice';
import { store } from '../../app/store';
import apiWrapper from '../api';
import { isNotificationAdminType } from './helper';

export default function consumeNotificationAction(
  item: API.Notification
): ((id?: string) => void) | undefined {
  switch (item.type) {
    default:
      return undefined;
    //increment with your actions when new notif type is created
  }
}

export function getLinkFromNotification(item: API.Notification) {
  switch (item.type) {
    case 'administration':
      if (isNotificationAdminType(item) && item.value.state !== 'unlinked') {
        return `/administration/companies/${item.ref}`;
      }
      return undefined;
    case 'recoveryCode':
      return `/settings/security`;
    case 'emailInfo':
      return `/settings/profile`;
    default:
      return undefined;
  }
}

export function handleNotification(item: API.Notification) {
  switch (item.type) {
    case 'updateRights':
      apiWrapper.util.invalidateTags(['Rights']);
      break;
    case 'updateSlots':
      apiWrapper.util.invalidateTags(['Slots']);
      break;
    case 'administration':
      store.dispatch(addNotification(item));
      break;
    case 'deleteNotification':
      return store.dispatch(removeNotification(item));
    default:
      store.dispatch(addNotification(item));
      break;
  }
}

export function getNotifLabel(item: API.Notification) {
  switch (item.type) {
    case 'report':
      switch (item.label) {
        case 'sent':
          return 'modal.support.success.message';
      }
      break;
    case 'administration':
      return AdminHandlers.getMessage(item);
    case 'emailInfo':
      return 'modal.notifications.subtitle_emailinfo';
    default:
      return item.label;
  }
}
