import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ScreenerPresetId, ScreenerPresetName, ScreenerPresetState } from './presetsTypes';
import { RootState } from '../../../app/store';

const savedPreset: { fund: ScreenerPresetState; asset: ScreenerPresetState } = {
  fund: { name: '', id: '' },
  asset: { name: '', id: '' },
};

const initialState: { fund: ScreenerPresetState; asset: ScreenerPresetState } = { ...savedPreset };

export const screenerPresetSlice = createSlice({
  name: 'screenerPreset',
  initialState,
  reducers: {
    updateScreenerPresetId: (
      state,
      action: PayloadAction<ScreenerPresetId & { type: 'fund' | 'asset' }>
    ) => {
      const type = action.payload.type;
      state[type].id = action.payload.id;
    },
    updateScreenerPresetName: (
      state,
      action: PayloadAction<ScreenerPresetName & { type: 'fund' | 'asset' }>
    ) => {
      const type = action.payload.type;
      state[type].name = action.payload.name;
    },
    hardResetScreenerPreset: (state, action: PayloadAction<{ type: 'fund' | 'asset' }>) => {
      const type = action.payload.type;
      state[type].name = '';
      state[type].id = '';
    },
  },
});

export const currentFundScreenerPreset = (state: RootState) => state.screenerPreset.fund;
export const currentAssetScreenerPreset = (state: RootState) => state.screenerPreset.asset;

export const { updateScreenerPresetId, updateScreenerPresetName, hardResetScreenerPreset } =
  screenerPresetSlice.actions;

export const screenerPresetReducer = screenerPresetSlice.reducer;
