import { VisibilityState } from '@tanstack/react-table';

export function visibilityStateToColsToIgnore(columnVisibility?: VisibilityState) {
  return Object.keys(columnVisibility || {}).filter((key) => !columnVisibility?.[key]);
}

export function colsToIgnoreToVisibilityState(colsToIgnore?: string[]): VisibilityState {
  return (
    colsToIgnore?.reduce(
      (columnVisibility, column) => ({ ...columnVisibility, [column]: false }),
      {} as VisibilityState
    ) || {}
  );
}
