import { useEffect, useState } from 'react';
import { dequal } from 'dequal/lite';
import { TableState } from '@tanstack/react-table';
import { TTableRef } from '@/components/Table4/helpers/types';

export default function useGetTableStateFromRef<T>(tableRef: TTableRef<T>): Partial<TableState> {
  const _state = tableRef.current?.getState();
  const [state, setState] = useState<Partial<TableState>>({});

  useEffect(() => {
    setState((prev) => {
      if (_state && !dequal(_state, prev)) return _state;
      return prev;
    });
  }, [_state]);

  return state;
}
